import React from 'react';
import { NotificationSummary } from './NotificationSummary/NotificationSummary';

export function NotificacaoResumo() {
  return (
    <>
      <NotificationSummary />
    </>
  );
}
