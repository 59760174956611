import { Box, InputAdornment, makeStyles, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { PartnerInvoiceStatus } from '@omega-energia/utilities';
import styles from '../Parceiros.style';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import { INITIAL_FILTER_STATE } from '../ParceirosTableConstants';
import CnpjOuNomeInput from '../../../../BaseUsuarios/components/CnpjOuNomeInput/CnpjOuNomeInput';

const useStyles = makeStyles(styles);

export const ParceirosParametrosFilters = props => {
  const { filters, setFilters, hasFastFilterSelected, setSelectedFilter } = props;
  const classes = useStyles(props);

  const handleChangeFilter = (filter, value) => {
    const newFilters = hasFastFilterSelected ? { ...INITIAL_FILTER_STATE } : { ...filters };

    newFilters[filter] = value;
    setFilters({ ...newFilters });
    setSelectedFilter(undefined);
  };

  const handleFilterReferenceDate = (date, filter) => {
    const jsDate = new Date(date);
    const dateParsed = DateTime.fromJSDate(jsDate)
      .startOf('month')
      .toFormat('yyyy-MM-dd');
    handleChangeFilter(filter, dateParsed);
  };

  return (
    <TableHeader.Filters>
      <Box sx={{ display: 'flex', width: '100%', '& > *': { flex: 1 } }}>
        <TableHeader.Input>
          <CnpjOuNomeInput
            className={classes.filterInput}
            label="Parceiro"
            value={filters.partner || ''}
            onChange={e => handleChangeFilter('partner', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: 'rgba(0, 0, 0, 0.6)' },
            }}
          />
        </TableHeader.Input>
        <TableHeader.Input>
          <TextField
            select
            label="Status"
            value={filters.status || ''}
            onChange={e => handleChangeFilter('status', e.target.value)}
            className={classes.filterInput}
            fullWidth
          >
            {Object.values(PartnerInvoiceStatus).map(status => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </TableHeader.Input>
        <TableHeader.Input>
          <KeyboardDatePicker
            disableToolbar
            className={classes.datePicker}
            label="Período"
            InputLabelProps={{
              style: { color: 'rgba(0, 0, 0, 0.6)' },
            }}
            value={DateTime.fromISO(filters.referenceDate).toISO()}
            onChange={newDate => handleFilterReferenceDate(newDate, 'referenceDate')}
            format="dd/MM/yyyy"
            initialFocusedDate={DateTime.local().startOf('day')}
            maxDate={DateTime.local().endOf('year')}
            views={['year', 'month']}
          />
        </TableHeader.Input>
      </Box>
    </TableHeader.Filters>
  );
};

ParceirosParametrosFilters.propTypes = {
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    statusContratacao: PropTypes.string,
    distribuidora: PropTypes.string,
    inicioContratacao: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
};
