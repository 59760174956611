import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DateTime } from 'luxon';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../components';
import ContentStringFormatter from '../../../helpers/ContentStringFormatter';
import styles from './CRMIntegracao.styles';
import { useCRMIntegracao } from './CRMIntegracaoProvider';

export const parseStatus = status => {
  const statusMap = {
    synced: 'SINCRONIZADO',
    failed: 'FALHA',
  };

  return statusMap[status] || status;
};

function CRMIntegracaoTable({ classes }) {
  const { attempts, total, isLoading, error, pagination, setPage, setTake } = useCRMIntegracao();

  const rootClassName = classNames(classes.root);

  if (error) {
    return <Typography variant="h6">{error.message}</Typography>;
  }

  if (!isLoading && Array.isArray(attempts) && total === 0) {
    return <Typography variant="h6">Não há integrações neste momento</Typography>;
  }

  if (isLoading || !attempts) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="subtitle1">ID</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1">Descrição</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1">Status</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1">Razão do Status</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1">Data da integração</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attempts?.map(attempt => (
                <TableRow
                  className={classNames(classes.tableRow, {
                    [classes.failedRow]: attempt.status === 'failed', // Aplica a classe condicional
                  })}
                  key={attempt.id}
                >
                  <TableCell align="center">
                    <Box>
                      <Typography variant="subtitle1">{attempt.id}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.tableCellInner}>
                      <Box>
                        <Typography variant="subtitle1">
                          {ContentStringFormatter.breakLine(attempt.description)}
                        </Typography>
                        <Typography variant="caption">{attempt.descricao}</Typography>
                      </Box>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Typography variant="subtitle1">{parseStatus(attempt.status)}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">
                      {ContentStringFormatter.breakLine(attempt.statusReason)}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">
                      {DateTime.fromISO(attempt.createdAt).toLocaleString({
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <ControlsTablePagination
                  total={total}
                  page={pagination.page}
                  rowsPerPage={pagination.take}
                  onChangeRowsPerPage={e => setTake(Number(e.target.value))}
                  onChangePage={(e, newPage) => setPage(newPage)}
                  labelRowsPerPage="Linhas por página"
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

CRMIntegracaoTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CRMIntegracaoTable);
