import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, FormControl, InputLabel, Select, MenuItem, Table } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import TableHeader from '../../../../../../theme/components/TableHeader/TableHeader';
import styles from './NotificationSummaryParameters.style';
import { useNotificationSummary } from '../../NotificationSummaryProvider';

const useStyles = makeStyles(styles);

export function NotificationSummaryParameters() {
  const classes = useStyles();
  const {
    uniqueTemplates,
    uniqueChannels,
    uniqueProviders,
    setFilter,
    resetFilter,
    parameters,
  } = useNotificationSummary();

  return (
    <Table className={classes.tableParameters}>
      <TableHeader>
        <TableHeader.Header>
          <TableHeader.Header.Title>
            <Typography variant="subtitle1">Resumo</Typography>
            <Typography variant="subtitle2">
              * O relatório contém o quantitativo dos últimos 6 meses por padrão
            </Typography>
          </TableHeader.Header.Title>
          <TableHeader.OptionsLeft>
            <TableHeader.Input>
              <Button color="primary" className={classes.buttonClearFilter} onClick={resetFilter}>
                LIMPAR FILTROS
              </Button>
            </TableHeader.Input>
          </TableHeader.OptionsLeft>
        </TableHeader.Header>

        <TableHeader.Filters>
          <TableHeader.Input>
            <FormControl>
              <InputLabel className={classes.labelSelectNotificationSummary} id="label-select-notification-summary">
                Template
              </InputLabel>
              <Select
                labelId="selectNotificationSummaryTemplate"
                id="select-notification-summary-template"
                value={parameters.filter.templateName}
                className={classes.filterInput}
                onChange={e => {
                  setFilter({ templateName: e.target.value });
                }}
              >
                <MenuItem key="all" value="all">
                  Todos
                </MenuItem>
                {uniqueTemplates.map(template => (
                  <MenuItem key={template.templateName} value={template.templateName}>
                    {template.templateLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableHeader.Input>
          <TableHeader.Input>
            <FormControl>
              <InputLabel
                className={classes.labelSelectNotificationSummary}
                id="label-select-notification-summary-channel"
              >
                Canal
              </InputLabel>
              <Select
                labelId="selectNotificationSummaryChannel"
                id="select-notification-summary-channel"
                value={parameters.filter.channel}
                className={classes.filterInput}
                onChange={e => {
                  setFilter({ channel: e.target.value });
                }}
              >
                <MenuItem key="all" value="all">
                  Todos
                </MenuItem>
                {uniqueChannels.map(channel => (
                  <MenuItem key={channel} value={channel}>
                    {capitalize(channel)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableHeader.Input>
          <TableHeader.Input>
            <FormControl>
              <InputLabel
                className={classes.labelSelectNotificationSummary}
                id="label-select-notification-summary-channel"
              >
                Provedor
              </InputLabel>
              <Select
                labelId="selectNotificationSummaryProvider"
                id="select-notification-summary-provider"
                value={parameters.filter.actualProvider}
                className={classes.filterInput}
                onChange={e => {
                  setFilter({ actualProvider: e.target.value });
                }}
              >
                <MenuItem key="all" value="all">
                  Todos
                </MenuItem>
                {uniqueProviders.map(provider => (
                  <MenuItem key={provider} value={provider}>
                    {capitalize(provider)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableHeader.Input>
          <TableHeader.Input>
            <KeyboardDatePicker
              disableToolbar
              className={classes.datePicker}
              id="notification-start-date-picker"
              label="Data Inicial"
              InputLabelProps={{
                style: { color: 'rgba(0, 0, 0, 0.6)' },
              }}
              value={parameters.filter.startDate}
              onChange={newDate => setFilter({ ...parameters.filter, startDate: newDate.startOf('day').toJSDate() })}
              format="dd/MM/yyyy"
              maxDate={new Date()}
              maxDateMessage="Data futura não permitida"
              minDate={DateTime.local()
                .minus({ months: 6 })
                .startOf('day')
                .toJSDate()}
              minDateMessage="Data máxima permitida: 6 meses"
            />
          </TableHeader.Input>

          <TableHeader.Input>
            <KeyboardDatePicker
              disableToolbar
              className={classes.datePicker}
              id="notification-end-date-picker"
              label="Data Final"
              InputLabelProps={{
                style: { color: 'rgba(0, 0, 0, 0.6)' },
              }}
              value={parameters.filter.endDate}
              onChange={newDate => setFilter({ ...parameters.filter, endDate: newDate.endOf('day').toJSDate() })}
              format="dd/MM/yyyy"
              maxDate={new Date()}
              maxDateMessage="Data futura não permitida"
              minDate={DateTime.local()
                .minus({ months: 6 })
                .startOf('day')
                .toJSDate()}
              minDateMessage="Data máxima permitida: 6 meses"
            />
          </TableHeader.Input>
        </TableHeader.Filters>
      </TableHeader>
    </Table>
  );
}
