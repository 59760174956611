import React from 'react';
import { Table, TableBody, TableContainer, TableRow, TableFooter, Typography, Box } from '@material-ui/core';
import { EnhancedTableHead } from './components/EnhancedTableHead';
import { FilterForm } from './components/FilterForm';
import Loading from '../../../components/Loading/Loading';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../components';
import EnhancedTableRow from './components/EnhancedTableRow';
import { useCredentialStatusTracker } from './CredentialStatusTrackerProvider';

export function CredentialStatusTracker() {
  const {
    energyUtilityCredentialsWithTotalCount,
    isFetchingEnergyUtilityCredentialsWithTotalCount,
    energyUtilityCredentialsFilterOptions,
    isFetchingEnergyUtilityCredentialsFilterOptions,
    parametros,
    errorEnergyUtilityCredentialsFilterOptions,
    errorFetchEnergyUtilityCredentialsPaginated,
    setFilter,
  } = useCredentialStatusTracker();
  const { statuses, energyUtilities } = energyUtilityCredentialsFilterOptions || {};
  const [count, energyUtilityCredentials] = energyUtilityCredentialsWithTotalCount || [];

  if (errorFetchEnergyUtilityCredentialsPaginated || errorEnergyUtilityCredentialsFilterOptions) {
    return <Typography variant="h6">Não foi possível obter os dados</Typography>;
  }

  if (isFetchingEnergyUtilityCredentialsWithTotalCount || isFetchingEnergyUtilityCredentialsFilterOptions) {
    return <Loading />;
  }

  const mappedEnergyUtilityCredentials =
    energyUtilityCredentials?.map(item => {
      return {
        ...item,
        status: statuses.find(s => s.value === item.status)?.label || item.status,
        energyUtilityAcronym:
          energyUtilities.find(s => s.value === item.energyUtilityAcronym)?.label || item.energyUtilityAcronym,
      };
    }) || [];

  return (
    <div>
      <Portlet>
        <PortletContent>
          <FilterForm filterOptions={energyUtilityCredentialsFilterOptions} />
        </PortletContent>
      </Portlet>
      <Portlet>
        <PortletContent>
          <TableContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box textAlign="left">
                <Typography variant="h6" gutterBottom>
                  Listagem dos status das Credenciais
                </Typography>

                <Typography variant="subtitle2" gutterBottom>
                  Segue a lista com todos os status das execuções para obtenção das faturas na distribuidora por unidade
                  consumidora
                </Typography>
              </Box>
            </Box>

            <Table>
              <EnhancedTableHead />

              <TableBody>
                {mappedEnergyUtilityCredentials.map(mappedEnergyUtilityCredential => {
                  return (
                    <EnhancedTableRow
                      key={mappedEnergyUtilityCredential.publicId}
                      data={mappedEnergyUtilityCredential}
                    />
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <ControlsTablePagination
                    total={count}
                    page={parametros.filter?.offset}
                    rowsPerPage={parametros.filter?.limit}
                    onChangeRowsPerPage={e => setFilter('limit', e.target.value)}
                    onChangePage={(e, page) => setFilter('offset', page)}
                    labelRowsPerPage="Linhas por página"
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </PortletContent>
      </Portlet>
    </div>
  );
}
