import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import styles from '../Errors.style';
import { INITIAL_FILTER_STATE } from '../ErrorsTableConstants';

const useStyles = makeStyles(styles);

export const ErrorsParametrosHeader = props => {
  const { filters, setFilters } = props;
  const classes = useStyles(props);

  const clearFiltersDisabled = Object.values(filters).filter(fil => fil != null).length === 0;

  return (
    <TableHeader.Header>
      <TableHeader.Header.Title>
        <Typography variant="subtitle1">Busca & Filtros</Typography>
      </TableHeader.Header.Title>
      <TableHeader.OptionsLeft>
        <TableHeader.Input>
          <Button
            color="primary"
            className={classes.buttonClearFilter}
            disabled={clearFiltersDisabled}
            onClick={() => setFilters(INITIAL_FILTER_STATE)}
          >
            LIMPAR FILTROS
          </Button>
        </TableHeader.Input>
      </TableHeader.OptionsLeft>
    </TableHeader.Header>
  );
};

ErrorsParametrosHeader.propTypes = {
  filters: PropTypes.shape({
    partner: PropTypes.string,
    status: PropTypes.string,
    referenceDate: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};
