import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, Box, Typography, TableHead, Table, TableBody } from '@material-ui/core';
import styles from './NotificationSummaryTable.style';

const useStyles = makeStyles(styles);

export function NotificationSummaryTableRowDetail({ template }) {
  const classes = useStyles();
  return (
    <>
      <Box width="100%">
        <Typography variant="h6">Detalhes</Typography>
        <Box display="flex" justifyContent="space-between">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID do Template no Core Service</TableCell>
                <TableCell>Enviados com sucesso</TableCell>
                <TableCell>Falhas de envio</TableCell>
                <TableCell>Pendentes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{template?.templateName}</TableCell>
                <TableCell>{template?.successCount}</TableCell>
                <TableCell>{template?.failedCount}</TableCell>
                <TableCell>{template?.pendingCount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box width="100%" className={classes.boxOfVariables}>
        <Typography variant="h6">Variáveis</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Variável</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {template.lastSuccessfulMetadata &&
              Object.entries(template.lastSuccessfulMetadata).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>
                    <Box>
                      <Typography variant="subtitle1">{key}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="subtitle1">{value}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
