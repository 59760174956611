const INITIAL_FILTER_STATE = {
  indicatorPublicId: null,
  indicatedPublicId: null,
  status: null,
  featureError: null,
};

const INITIAL_SORT_STATE = {};

export { INITIAL_FILTER_STATE, INITIAL_SORT_STATE };
