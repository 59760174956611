import { Box, InputAdornment, makeStyles, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { ErrorStatus, FeatureError } from '@omega-energia/utilities';
import styles from '../Errors.style';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import { INITIAL_FILTER_STATE } from '../ErrorsTableConstants';

const useStyles = makeStyles(styles);

export const ErrorsParametrosFilters = props => {
  const { filters, setFilters, hasFastFilterSelected, setSelectedFilter } = props;
  const classes = useStyles(props);

  const handleChangeFilter = (filter, value) => {
    const newFilters = hasFastFilterSelected ? { ...INITIAL_FILTER_STATE } : { ...filters };

    newFilters[filter] = value;
    setFilters({ ...newFilters });
    setSelectedFilter(undefined);
  };

  return (
    <TableHeader.Filters>
      <Box sx={{ display: 'flex', width: '100%', '& > *': { flex: 1 } }}>
        <TableHeader.Input>
          <TextField
            className={classes.filterInput}
            label="ID do Indicador"
            value={filters.indicatorPublicId || ''}
            onChange={e => handleChangeFilter('indicatorPublicId', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: 'rgba(0, 0, 0, 0.6)' },
            }}
          />
        </TableHeader.Input>
        <TableHeader.Input>
          <TextField
            className={classes.filterInput}
            label="ID do Indicado"
            value={filters.indicatedPublicId || ''}
            onChange={e => handleChangeFilter('indicatedPublicId', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} fontSize="large" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: 'rgba(0, 0, 0, 0.6)' },
            }}
          />
        </TableHeader.Input>
        <TableHeader.Input>
          <TextField
            select
            label="Status"
            value={filters.status || ''}
            onChange={e => handleChangeFilter('status', e.target.value)}
            className={classes.filterInput}
            fullWidth
          >
            {Object.values(ErrorStatus).map(status => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </TableHeader.Input>

        <TableHeader.Input>
          <TextField
            select
            label="Funcionalidade"
            value={filters.featureError || ''}
            onChange={e => handleChangeFilter('featureError', e.target.value)}
            className={classes.filterInput}
            fullWidth
          >
            {Object.values(FeatureError).map(featureError => (
              <MenuItem key={featureError} value={featureError}>
                {featureError}
              </MenuItem>
            ))}
          </TextField>
        </TableHeader.Input>
      </Box>
    </TableHeader.Filters>
  );
};

ErrorsParametrosFilters.propTypes = {
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    statusContratacao: PropTypes.string,
    distribuidora: PropTypes.string,
    inicioContratacao: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
};
