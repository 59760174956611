export default theme => ({
  tabs: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  listItem: {
    borderBottom: '1px solid #E3E3E3',
  },
  selected: {
    backgroundColor: theme.palette.primary.serena,
  },
  agenteNome: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    width: '300px',
  },
  bullet: {
    display: 'list-item',
    listStyleType: 'disc',
    marginLeft: '22px',
  },
  nomeUsuarioContent: {
    display: 'flex',
    alignItems: 'center',
  },
  iconBlocker: {
    width: 60,
    height: 60,
  },
});
