import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { usePaginationClientSide } from '../../../theme/hooks/usePaginationClientSide';

const CRMIntegracaoContext = createContext({
  attempts: [],
  getPersisAttempts() {},
  isLoading: false,
  error: null,
  setPaginationParameters() {},
  setSort() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  pagination: {
    page: 1,
    take: 10,
  },
  totalPages: 1,
  filters: {},
  sort: {},
});

export const CRMIntegracaoProvider = ({ children }) => {
  const {
    data: attempts,
    allData: allAttempts,
    isFetching: isLoading,
    error,
    filters,
    sort,
    pagination,
    totalPages,
    refetch,
    setSort,
    setFilters,
    resetFilter,
    setTake,
    setPage,
  } = usePaginationClientSide({
    queryName: 'fetch-persis-attempts',
    url: '/crm/persist-attempts',
  });

  return (
    <CRMIntegracaoContext.Provider
      value={{
        attempts,
        total: allAttempts?.length,
        isLoading,
        error,
        pagination,
        totalPages,
        filters,
        sort,
        getPersisAttempts: refetch,
        setPage,
        setTake,
        setSort,
        setFilter: setFilters, // Renomeado para manter consistência
        resetFilter,
      }}
    >
      {children}
    </CRMIntegracaoContext.Provider>
  );
};

export const useCRMIntegracao = () => useContext(CRMIntegracaoContext);

CRMIntegracaoProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
