import { Button, makeStyles, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import styles from '../Clientes.style';
import { INITIAL_FILTER_STATE } from '../ClientesTableConstants';

const useStyles = makeStyles(styles);

export const ClientesParametrosHeader = props => {
  const { handleDownloadAgents, filters, setFilters } = props;
  const classes = useStyles(props);

  const clearFiltersDisabled = Object.values(filters).filter(fil => fil != null).length === 0;

  return (
    <TableHeader.Header>
      <TableHeader.Header.Title>
        <Typography variant="subtitle1">Busca & Filtros</Typography>
      </TableHeader.Header.Title>
      <TableHeader.OptionsLeft>
        <TableHeader.Input>
          <Button
            color="primary"
            className={classes.buttonClearFilter}
            disabled={clearFiltersDisabled}
            onClick={() => setFilters(INITIAL_FILTER_STATE)}
          >
            LIMPAR FILTROS
          </Button>
          <Button color="primary" onClick={handleDownloadAgents} variant="contained" startIcon={<GetApp />}>
            BAIXAR PLANILHA
          </Button>
        </TableHeader.Input>
      </TableHeader.OptionsLeft>
    </TableHeader.Header>
  );
};

ClientesParametrosHeader.propTypes = {
  handleDownloadAgents: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    distribuidora: PropTypes.string,
    dataFechamento: PropTypes.string,
    parceiro: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};
