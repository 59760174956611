import { formatCnpj } from '@brazilian-utils/formatters';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { formatCelular } from '@omega-energia/utilities';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useIntl } from 'react-intl';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../../../components';
import TableColumnSortButton from '../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';
import styles from './GeracaoDistribuida.styles';
import { useGeracaoDistribuida } from '../../GeracaoDistribuidaProvider';
import ContentStringFormatter from '../../../../../helpers/ContentStringFormatter';

function GeracaoDistribuidasTable({ classes }) {
  const { geracoesDistribuidas, parametros, setSort, isLoading, error, setPage, setTake } = useGeracaoDistribuida();
  const rootClassName = classNames(classes.root);
  const intl = useIntl();

  const formatCadastro = createdAt => {
    return (
      <TableCell width="10%">
        <div className={classes.tableCellInner}>
          <Box display="flex" flexDirection="column" mr="1rem">
            <Typography variant="body2">{DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)}</Typography>

            <Typography variant="caption">{DateTime.fromISO(createdAt).toFormat('H:mm')}</Typography>
          </Box>
        </div>
      </TableCell>
    );
  };

  if (error) {
    return <Typography variant="h6">{error.message}</Typography>;
  }

  if (!isLoading && Array.isArray(geracoesDistribuidas) && geracoesDistribuidas.length === 0) {
    return <Typography variant="h6">There are no geracoesDistribuidas</Typography>;
  }

  if (isLoading || !geracoesDistribuidas) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="start" width="20%">
                  <Box display="flex" justifyContent="start" pl="4rem">
                    <Typography variant="subtitle1">
                      Usuário
                      <TableColumnSortButton
                        value={parametros.sort.nomeResponsavel || ''}
                        onChange={sort => setSort('nomeResponsavel', sort)}
                      />
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle1">
                    Contato
                    <TableColumnSortButton
                      value={parametros.sort.email || ''}
                      onChange={sort => setSort('email', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" width="30%">
                  <Typography variant="subtitle1">
                    Organização
                    <TableColumnSortButton
                      value={parametros.sort.razaoSocial || ''}
                      onChange={sort => setSort('razaoSocial', sort)}
                    />
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography variant="subtitle1">
                    Cadastro
                    <TableColumnSortButton
                      value={parametros.sort.createdAt || ''}
                      onChange={sort => setSort('createdAt', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1">
                    Distribuidora
                    <TableColumnSortButton
                      value={parametros.sort.distribuidora || ''}
                      onChange={sort => setSort('distribuidora', sort)}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {geracoesDistribuidas?.content?.map(geracaoDistribuida => (
                <>
                  <TableRow className={classes.tableRow} key={geracaoDistribuida.id}>
                    <TableCell align="left">
                      <Box display="flex" flexDirection="row" pl="4rem">
                        <Typography variant="subtitle1">{geracaoDistribuida.nomeResponsavel}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <div className={classes.tableCellInner}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="subtitle1">
                            {ContentStringFormatter.breakLine(geracaoDistribuida.email)}
                          </Typography>
                          <Typography variant="caption">{formatCelular(geracaoDistribuida.telefone)}</Typography>
                        </Box>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.tableCellInner}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="subtitle1">{geracaoDistribuida.razaoSocial}</Typography>
                          <Typography variant="caption">{formatCnpj(geracaoDistribuida.cnpj)}</Typography>
                        </Box>
                      </div>
                    </TableCell>

                    {formatCadastro(geracaoDistribuida.createdAt)}

                    <TableCell align="right">
                      <Box display="flex" justifyContent="end">
                        <div className={classes.tableCellInner}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="subtitle1">{geracaoDistribuida.distribuidora.descricao}</Typography>
                            <Typography variant="caption">
                              {intl.formatNumber(geracaoDistribuida.valorMedioConsumo, {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Typography>
                          </Box>
                        </div>
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <ControlsTablePagination
                  total={geracoesDistribuidas?.total}
                  page={parametros.page}
                  rowsPerPage={parametros.take}
                  onChangeRowsPerPage={e => setTake(e.target.value)}
                  onChangePage={(e, page) => setPage(page)}
                  labelRowsPerPage="Linhas por página"
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

GeracaoDistribuidasTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GeracaoDistribuidasTable);
