export default theme => ({
  root: {},
  progressWrapper: {
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxOfVariables: {
    marginTop: '1rem',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    cursor: 'pointer',
    height: '4rem',
  },
});
