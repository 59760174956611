import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Portlet, PortletContent } from '../../../../../components';
import { CampanhasTableRow } from './CampanhasTableRow';
import styles from './CampanhasTable.styles';

export default function CampanhasTable(props) {
  const { data: tableData, total, refetch } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles(props);
  const rootClassName = classNames(classes.root);

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <Table width="100%" className={classes.mainTable}>
          <TableHead>
            <TableRow>
              <TableCell width="15%">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  Nome Campanha
                </Typography>
              </TableCell>

              <TableCell width="15%">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  Redirecionamento
                </Typography>
              </TableCell>

              <TableCell width="20%">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  Imagem Desktop
                </Typography>
              </TableCell>

              <TableCell width="20%">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  Imagem Mobile
                </Typography>
              </TableCell>

              <TableCell width="15%">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  Data Início
                </Typography>
              </TableCell>

              <TableCell width="15%">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  Data Fim
                </Typography>
              </TableCell>

              <TableCell width="15%">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  Status
                </Typography>
              </TableCell>

              <TableCell width="15%">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  Ações
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData?.map(campanha => {
              return (
                <CampanhasTableRow key={campanha.id} clientesData={campanha} refetch={refetch} classes={classes} />
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={7}>{total}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </PortletContent>
    </Portlet>
  );
}

CampanhasTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
};
