import React from 'react';
import { TableHead, TableCell, TableRow } from '@material-ui/core';

const headCells = [
  {
    id: 'consumerUnitCode',
    label: 'Código Unidade',
  },
  {
    id: 'energyUtilityAcronym',
    label: 'Distribuidora',
  },
  {
    id: 'identificationNumber',
    label: 'Documento',
  },
  {
    id: 'createdAt',
    label: 'Data de Criação',
  },
  {
    id: 'updatedAt',
    label: 'Última Atualização',
  },
  {
    id: 'externalId',
    label: 'ID Externo Instalação',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'labelStatus',
    label: 'Status Área Logada',
  },
  {
    id: 'description',
    label: 'Descrição',
  },
  {
    id: 'lastReadingDate',
    label: 'Última leitura',
  },
  {
    id: 'monitorLastRefresh',
    label: 'Última execução',
  },
];

export function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
