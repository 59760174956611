import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  TextField,
  MenuItem,
  Snackbar,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { req } from '@omega-energia/react';
import { ErrorStatus } from '@omega-energia/utilities';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../../auth/authProvider';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
  },
  dialogContentWidth: {
    '& .MuiPaper-root': {
      minWidth: '450px',
    },
  },
});

function ModalEditarDados({ isOpen, onClose, id, errorData, refetch }) {
  const { token } = useAuth();

  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async data => {
    try {
      setIsLoading(true);
      await req.patch(`/backstage/comunidade/errors/${id}`, data, { token });
      setSnackbar({ open: true, message: 'Log de erro atualizado com sucesso!' });
      setTimeout(() => {
        onClose();
        refetch();
      }, 2000);
    } catch (error) {
      setSnackbar({
        open: true,
        message: error?.response?.data?.message || 'Houve um erro ao atualizar o log de erro',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={isOpen} className={classes.dialogContentWidth}>
        <DialogTitle>
          <Typography className={classes.title}>Edição Log de Erro</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="row" mb={2} style={{ gap: '12px' }}>
              <TextField
                select
                variant="outlined"
                label="Status"
                name="status"
                placeholder="Selecione o Status"
                style={{ flex: 1 }}
                defaultValue={errorData?.status}
                {...register('status', {
                  required: 'Este campo é obrigatório',
                })}
                onChange={event => setValue('status', event.target.value)}
                error={!!errors.status}
                helperText={errors.status?.message?.toString()}
              >
                {Object.values(ErrorStatus).map(status => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <DialogActions>
              <Button type="button" onClick={() => onClose()} color="primary" disabled={isLoading}>
                Cancelar
              </Button>
              <Button type="submit" color="primary" disabled={isLoading} style={{ minWidth: '75px' }}>
                {isLoading ? <CircularProgress size="14px" /> : 'Salvar'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        message={snackbar.message}
        onClose={() => setSnackbar({ open: false, message: '' })}
      />
    </>
  );
}

ModalEditarDados.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default ModalEditarDados;
