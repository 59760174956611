import palette from '../../../../../theme/palette';

export default () => ({
  dialog: {
    height: '516px',
  },
  dialogTitle: {
    color: `${palette.primary.serena}`,
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    '& > *': {
      color: `${palette.primary.serena}`,
    },
  },
  tableHead: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
    '& > *': {
      paddingBottom: 16,
    },
  },
  tableBody: {
    display: 'table',
    width: '100%',
    overflow: 'auto',
  },
});
