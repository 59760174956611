const INITIAL_FILTER_STATE = {
  partner: null,
  status: null,
  referenceDate: null,
};

const INITIAL_SORT_STATE = {
  partner: undefined,
};

export { INITIAL_FILTER_STATE, INITIAL_SORT_STATE };
