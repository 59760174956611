import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Snackbar,
} from '@material-ui/core';
import { useMutation, useQueryCache } from 'react-query';
import { useForm } from '@omega-energia/react';
import axios from 'axios';
import styles from './UploadCsv.styles';
import { useAuth } from '../../auth/authProvider';
import FileDownloadArrayBuffer from '../../helpers/FileDownloadArrayBuffer';

const useStyles = makeStyles(styles);

function CsvUploadWithErrorFile({ open, onClose, config }) {
  const { token } = useAuth();
  const classes = useStyles();
  const queryCache = useQueryCache();
  const inputRef = useRef(null);

  const [alert, setAlert] = useState({ show: false, msg: '' });

  const handleCloseAlert = () => setAlert({ show: false, msg: '' });

  const [uploadFile] = useMutation(
    async formData => {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/${config.url}`, formData, {
        contentType: 'multipart/form-data',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    },
    {
      onMutate: () => setAlert({ show: true, msg: config.alert.init }),
      onError: error => {
        if (error?.response?.headers['content-type']?.includes('text/csv')) {
          FileDownloadArrayBuffer.downloadFile(new Blob([error.response.data]), 'error.csv');
        }
        setAlert({ show: true, msg: `${config.alert.error}: ${error.message || 'Erro desconhecido'}` });
      },
      onSuccess: () => {
        setAlert({ show: true, msg: config.alert.success });
        queryCache.invalidateQueries(config.queryCacheId);
      },
    },
  );

  // eslint-disable-next-line no-unused-vars
  const [formValues, formOnChange, formOnSubmit, _, setInput] = useForm({ file: null }, async data => {
    const formDataToSend = new FormData();

    if (!data.file?.files?.length) {
      return;
    }

    if (config.data) {
      Object.keys(config.data).forEach(key => {
        formDataToSend.append(key, config.data[key]);
      });
    }

    formDataToSend.append('file', data.file.files[0]);
    uploadFile(formDataToSend);
    setInput({ file: null });
    onClose();
  });

  return (
    <>
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <form
          onChange={formOnChange}
          onSubmit={e => {
            e.preventDefault();
            formOnSubmit();
          }}
        >
          <DialogTitle>
            <Typography variant="body1">{config.dialog.title}</Typography>
          </DialogTitle>
          <DialogContent>
            <Box px={2} py={4} display="flex" alignItems="center" justifyContent="center">
              <label htmlFor="upload-file">
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'rgba(33, 33, 33, 0.08)' }}
                  size="large"
                  onClick={() => inputRef.current.click()}
                >
                  Escolher arquivo
                </Button>

                <input
                  ref={inputRef}
                  type="file"
                  id="upload-file"
                  name="file"
                  style={{ display: 'none' }}
                  onChange={e => setInput({ file: e.target })}
                />
              </label>

              <Typography style={{ marginLeft: '1rem' }} variant="caption">
                {formValues.file?.files[0]?.name || 'Nenhum Arquivo Selecionado'}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={!formValues.file?.files[0]}>
              Enviar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={alert.show}
        message={alert.msg}
        onClose={handleCloseAlert}
      />
    </>
  );
}

CsvUploadWithErrorFile.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  config: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alert: PropTypes.shape({
      init: PropTypes.string.isRequired,
      error: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
    }).isRequired,
    queryCacheId: PropTypes.string.isRequired,
    dialog: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CsvUploadWithErrorFile;
