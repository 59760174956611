import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { FileDownload } from '@omega-energia/react';
import { Typography } from '@material-ui/core';
import { ClientesParametros } from './ClientesParametros/ClientesParametros';
import { INITIAL_FILTER_STATE, INITIAL_SORT_STATE } from './ClientesTableConstants';
import { useAuth } from '../../../../auth/authProvider';
import request from '../../../../services/network/request';
import ClientesParametrosTable from './ClientesParametros/ClientesParametrosTable';

const Clientes = () => {
  const { token } = useAuth();
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState(INITIAL_FILTER_STATE);
  const [sorts, setSorts] = useState(INITIAL_SORT_STATE);
  const [selectedFilter, setSelectedFilter] = useState(undefined);

  const checkInputs = () => {
    if (filters?.contraparte) {
      return filters.contraparte.length >= 3 || filters.contraparte.length === 0;
    }
    if (filters?.distribuidora) {
      return filters.distribuidora.length >= 3 || filters.distribuidora.length === 0;
    }

    return true;
  };

  const parseValues = obj => {
    const values = Object.entries(obj).map(item => {
      return item[1] ? `${item[0]}:${item[1]}` : '';
    });
    return values.filter(term => term !== '').join(',');
  };

  const { isFetching: isLoading } = useQuery(
    ['fetch-clientes-bs', currentPage, rowsPerPage, filters, sorts],
    () => {
      const params = { sort: parseValues(sorts), page: currentPage, take: rowsPerPage, filters: parseValues(filters) };
      return request.get('backstage/comunidade/clientes', { token, params });
    },
    {
      onSuccess: data => {
        setTableData([...data.content]);
        setTotal(data.total);
        setCurrentPage(data.currentPage);
      },
      enabled: checkInputs(),
    },
  );

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleDownloadAgents = async () => {
    const params = {
      filters: parseValues(filters),
      sort: parseValues(sorts),
    };

    const file = await request.get('backstage/comunidade/clientes/download', {
      token,
      params,
      responseType: 'arrayBuffer',
    });

    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), file], { type: 'text/csv' });

    FileDownload.downloadFile(blob, 'clientes-comissionaveis');
  };

  const changeFilters = filter => {
    setCurrentPage(1);
    setFilters(filter);
  };

  return (
    <>
      <ClientesParametros
        handleDownloadAgents={handleDownloadAgents}
        filters={filters}
        setFilters={changeFilters}
        hasFastFilterSelected={!!selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />

      {!isLoading && tableData.length > 0 && (
        <ClientesParametrosTable
          data={tableData}
          setClientes={setTableData}
          setSorts={setSorts}
          sorts={sorts}
          total={total}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      {!isLoading && tableData.length === 0 && <Typography>Ops. Não encontramos nada.</Typography>}
    </>
  );
};

export { Clientes };
