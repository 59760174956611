import React from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { TabsURL } from '../../helpers/enums';
import TabPermissions from '../../components/TabPermissions/TabPermissions';
import { CredentialStatusTrackerProvider } from './CredentialStatusTracker/CredentialStatusTrackerProvider';
import { CredentialStatusTracker } from './CredentialStatusTracker/CredentialStatusTracker';

export const tabsCredentialManagement = Object.freeze({
  [TabsURL.CREDENTIAL_MANAGEMENT__MONITOR_EXECUTION_STATUS]: {
    component: (
      <CredentialStatusTrackerProvider>
        <CredentialStatusTracker />
      </CredentialStatusTrackerProvider>
    ),
    label: 'Status FattureWeb',
  },
});

function CredentialManagement() {
  return (
    <DashboardLayout title="Gestão de Credenciais">
      <TabPermissions tabs={tabsCredentialManagement} />
    </DashboardLayout>
  );
}

export default CredentialManagement;
