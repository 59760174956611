import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Tabs, Tab } from '@material-ui/core';
import cls from 'classnames';
import { useRouteMatch, useHistory, Route, useParams, Switch } from 'react-router-dom';
import { Dashboard as DashboardLayout } from '../../layouts';
import styles from './BaseUsuarios.style';
import Consumidores from './Consumidores/Consumidores';
import Usuarios from './Usuarios/Usuarios';
import { ConsumerDetailTable, ConsumerAdd } from './Consumidores/components';
import Migracao from './Migracao/Migracao';
import Irecs from './Irecs/Irecs';
import GeracaoDistribuida from './GeracaoDistribuida/GeracaoDistribuida';
import OrganizationDetails from './Consumidores/OrganizationDetails';
import PreCadastro from './PreCadastro/PreCadastro';
import PreCadastroAdd from './PreCadastro/components/PreCadastroAdd/PreCadastroAdd';
import DistribuitionContract from './Consumidores/DistribuitionContract';
import Consultoria from './Consultoria/Consultoria';
import GerenciadorFuncionalidade from './GerenciadorFuncionalidade/GerenciadorFuncionalidade';

const TABS = Object.freeze({
  consumidor: 'consumidor',
  consultoria: 'consultoria',
  preCadastro: 'pre-cadastro',
  outros: 'outros',
  usuarios: 'usuarios',
  migracao: 'migracao',
  irec: 'irec',
  gd: 'gd',
  feature: 'gerenciador-funcionalidade',
});

function BaseUsuarios({ classes }) {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { page } = useParams();

  const [selectedTab, setSelectedTab] = useState(TABS.usuarios);

  function handleChangeTab(_, newValue) {
    setSelectedTab(newValue);
    history.push(`${path.replace('/:page', '')}/${newValue}`);
  }

  useEffect(() => {
    if (page) {
      setSelectedTab(page);
    }
  }, [page]);

  return (
    <DashboardLayout title="Cadastros">
      {selectedTab && (
        <>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            aria-label="disabled tabs example"
            className={cls(classes.tabs)}
          >
            <Tab label="CONSUMIDOR" value={TABS.consumidor} />
            <Tab label="CONSULTORIA" value={TABS.consultoria} />
            <Tab label="PRÉ-CADASTRO" value={TABS.preCadastro} />
            <Tab label="USUÁRIOS" value={TABS.usuarios} />
            <Tab label="MIGRAÇÃO" value={TABS.migracao} />
            <Tab label="IREC" value={TABS.irec} />
            <Tab label="GD" value={TABS.gd} />
            <Tab label="GERENCIADOR DE FUNCIONALIDADES" value={TABS.feature} />
          </Tabs>

          <Switch>
            <Route
              path={`/base-usuarios/${TABS.usuarios}`}
              render={() => (
                <div className={classes.content}>
                  <Usuarios />
                </div>
              )}
            />
            <Route
              path={`/base-usuarios/${TABS.consultoria}`}
              render={() => (
                <div className={classes.content}>
                  <Consultoria />
                </div>
              )}
            />
            <Route
              path={`/base-usuarios/${TABS.consumidor}/adicionar`}
              render={() => (
                <div className={classes.content}>
                  <ConsumerAdd />
                </div>
              )}
            />
            <Route
              exact
              path={`/base-usuarios/${TABS.consumidor}/:consumidorId`}
              render={() => (
                <div className={classes.content}>
                  <ConsumerDetailTable />
                </div>
              )}
            />
            <Route
              exact
              path={`/base-usuarios/${TABS.consumidor}`}
              render={() => (
                <div className={classes.content}>
                  <Consumidores />
                </div>
              )}
            />
            <Route
              exact
              path={`/base-usuarios/${TABS.preCadastro}`}
              render={() => (
                <div className={classes.content}>
                  <PreCadastro />
                </div>
              )}
            />
            <Route
              path={`/base-usuarios/${TABS.preCadastro}/novo`}
              render={() => (
                <div className={classes.content}>
                  <PreCadastroAdd />
                </div>
              )}
            />
            <Route
              exact
              path={`/base-usuarios/${TABS.consumidor}/gestao/cargas/:empresaMaeId`}
              render={() => (
                <div className={classes.content}>
                  <OrganizationDetails />
                </div>
              )}
            />
            <Route
              exact
              path={`/base-usuarios/${TABS.consumidor}/gestao/cargas/:empresaMaeId/:cargaId/cusd`}
              render={() => (
                <div className={classes.content}>
                  <DistribuitionContract />
                </div>
              )}
            />
            <Route
              exact
              path={`/base-usuarios/${TABS.migracao}`}
              render={() => {
                return (
                  <div className={classes.content}>
                    <Migracao />
                  </div>
                );
              }}
            />
            <Route
              exact
              path={`/base-usuarios/${TABS.irec}`}
              render={() => {
                return (
                  <div className={classes.content}>
                    <Irecs />
                  </div>
                );
              }}
            />
            <Route
              exact
              path={`/base-usuarios/${TABS.gd}`}
              render={() => {
                return (
                  <div className={classes.content}>
                    <GeracaoDistribuida />
                  </div>
                );
              }}
            />

            <Route
              exact
              path={`/base-usuarios/${TABS.feature}`}
              render={() => {
                return (
                  <div className={classes.content}>
                    <GerenciadorFuncionalidade />
                  </div>
                );
              }}
            />
          </Switch>
        </>
      )}
    </DashboardLayout>
  );
}

BaseUsuarios.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BaseUsuarios);
