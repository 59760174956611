import React, { Fragment, useState } from 'react';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatCnpj } from '@brazilian-utils/formatters';
import { formatVolume } from '@omega-energia/utilities';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { isNil } from 'lodash';
import styles from './ConsumidorDetalhe.style';
import TableColumnSortButton from '../../../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';
import ContentStringFormatter from '../../../../../../../helpers/ContentStringFormatter';
import { formatPrice } from '../../../../../../../helpers/utils';
import request from '../../../../../../../services/network/request';
import { useAuth } from '../../../../../../../auth/authProvider';

const ConsumidorDetalhe = props => {
  const { classes, consultoriaId } = props;
  const { token } = useAuth();
  const [parametro, setParametro] = useState({});

  const setSort = (column, value) => {
    setParametro({
      ...parametro,
      sort: {
        ...parametro.sort,
        [column]: value?.toUpperCase(),
      },
    });
  };

  const parsedSort = Object.values(parametro.sort || {})
    .filter(value => value)
    .join();

  const fetchClientes = React.useCallback(
    () => request.get(`/consultoria/clientes/bs?public_id=${consultoriaId}&sort=${parsedSort}`, { token }),
    [consultoriaId, parsedSort, token],
  );

  const { data: clientes, isFetching: isLoadingClientes } = useQuery(
    ['consultoria-clientes', consultoriaId, parsedSort],
    fetchClientes,
    {
      onError: error => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    },
  );

  if (clientes?.length === 0) {
    return (
      <div className={classes.progressWrapper}>
        <Typography variant="subtitle1" className={classes.colorText}>
          Não há cliente vinculado a esta consultoria
        </Typography>
      </div>
    );
  }

  if (isLoadingClientes || !clientes) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Table>
      <TableHead height="77.5rem">
        <TableRow>
          <TableCell align="start" width="19.2%">
            <Box display="flex" justifyContent="start">
              <Typography variant="subtitle1">Empresa</Typography>
            </Box>
          </TableCell>
          <TableCell align="center" width="25%">
            <Typography variant="subtitle1">Email</Typography>
          </TableCell>
          <TableCell align="center" className={classes.cellValorContratado}>
            <Typography variant="subtitle1">Valor do contrato</Typography>
          </TableCell>
          <TableCell align="center" width="15%">
            <Typography variant="subtitle1">Consumo médio</Typography>
          </TableCell>
          <TableCell align="right" style={{ paddingRight: '1.5rem' }} width="12%">
            <Typography variant="subtitle1" className={classes.formatTextAlign}>
              Cadastro
              <TableColumnSortButton
                value={parametro.sort?.cadastro || 'desc'}
                onChange={sort => setSort('cadastro', sort)}
              />
            </Typography>
          </TableCell>
          <TableCell align="center" padding="none" />
        </TableRow>
      </TableHead>
      <TableBody>
        {clientes?.map(cliente => (
          <Fragment key={cliente.cadastro}>
            <TableRow className={classes.tableRow}>
              <TableCell align="left">
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle1" className={classes.colorText}>
                    {ContentStringFormatter.clip(cliente.nome)}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.colorTextCaption}>
                    {formatCnpj(cliente.cnpj)}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="center">
                <div className={classes.tableCellInner}>
                  <Box display="flex" flexDirection="column" margin="auto">
                    <Typography variant="body1" className={classes.colorText}>
                      {ContentStringFormatter.clip(cliente.email)}
                    </Typography>
                  </Box>
                </div>
              </TableCell>
              <TableCell align="center">
                <div className={classes.tableCellInner}>
                  <Box display="flex" flexDirection="column" margin="auto">
                    <Typography variant="subtitle1" className={classes.textValorContratado}>
                      {isNil(cliente.valorContratado) || cliente.valorContratado === 0
                        ? '--'
                        : formatPrice(cliente.valorContratado)}
                    </Typography>
                  </Box>
                </div>
              </TableCell>
              <TableCell>
                <div className={classes.tableCellInner}>
                  <Box display="flex" flexDirection="column" margin="auto">
                    <Typography variant="subtitle1" className={classes.colorText}>
                      {isNil(cliente.consumoMedio) ? '--' : `${formatVolume(cliente.consumoMedio)} MWm`}
                    </Typography>
                  </Box>
                </div>
              </TableCell>
              <TableCell align="right" className={classes.boxOperacaoCadastro}>
                <Box display="flex" justifyContent="end">
                  <Typography variant="body2" className={classes.colorText}>
                    {DateTime.fromISO(cliente.cadastro).toLocaleString(DateTime.DATE_SHORT)}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );
};
export default withStyles(styles)(ConsumidorDetalhe);

ConsumidorDetalhe.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.shape().isRequired,
  consultoriaId: PropTypes.string.isRequired,
};
