import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { ErrorsTableRowStyles } from '../Errors.style';
import MenuButton from './MenuButton';
import ModalEditarDados from './ModalEditarDados';

export function ErrorsTableRow(props) {
  const { errorsData, refetch } = props;
  const useRowStyles = makeStyles(ErrorsTableRowStyles);
  const classes = useRowStyles();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <TableRow className={classes.tableRowStyle} style={{ cursor: 'pointer' }}>
        <TableCell width="15%">
          <Typography variant="body2">{errorsData?.indicatorPublicId ?? '--'}</Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {errorsData?.indicatedPublicId ?? '--'}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {errorsData?.errorMessage ?? '--'}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {errorsData?.featureError ?? '--'}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {errorsData?.errorType ?? '--'}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {errorsData?.status ?? '--'}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {DateTime.fromISO(errorsData?.createdAt).toFormat('dd/MM/yyyy')}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Tooltip title={errorsData?.errorJson ?? '--'} arrow>
            <Typography
              className={`${classes.tableCell} ${classes.truncatedText}`}
              variant="body2"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '20px',
              }}
            >
              {errorsData?.errorJson ?? '--'}
            </Typography>
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} onClick={event => event.stopPropagation()}>
          <MenuButton
            menuOptions={[
              {
                title: 'Editar',
                action: () => setModalOpen(!modalOpen),
              },
            ]}
          />
        </TableCell>
      </TableRow>

      <ModalEditarDados
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        id={errorsData?.id}
        errorData={errorsData}
        refetch={refetch}
      />
    </>
  );
}

ErrorsTableRow.propTypes = {
  errorsData: PropTypes.shape({
    partner: PropTypes.object,
    purchaseOrderNumber: PropTypes.string,
    status: PropTypes.string,
    amount: PropTypes.string,
    referenceDate: PropTypes.string,
    issueDate: PropTypes.string,
    paymentDate: PropTypes.string,
    refetch: PropTypes.func.isRequired,
  }).isRequired,
};
