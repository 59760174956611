import { hexToRgb, decomposeColor } from '@material-ui/core/styles';

const hexToRgba = (hex, opacity) => {
  const [r, g, b] = decomposeColor(hexToRgb(hex)).values;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export default theme => {
  const baseRowHeight = 55;
  const headRowHeight = 38;
  const tailRowsHeight = 34;

  return {
    addColuna: {
      display: 'flex',
      fontSize: 14,
      fontWeight: 500,
      alignItems: 'center',
      justifyContent: 'center',
    },
    csvButton: {
      paddingTop: '0px',
      marginLeft: 'auto',
      '& .MuiTypography-body1': {
        marginLeft: '0.625rem',
        fontSize: 14,
        fontWeight: 500,
        color: theme.palette.info.main,
      },
    },
    unfoldButton: {
      variant: 'contained',
      padding: '0px',
      margin: '0px',
      maxWidth: '0px',
      minWidth: '0px',
      maxHeight: '0px',
      minHeight: '0px',
      transform: 'rotate(90deg)',
    },
    toggleVolatil: {
      paddingTop: '0px',
      marginLeft: '10',
      alignItems: 'center',
    },
    label: {
      marginRight: '15px',
    },
    error: {
      color: theme.palette.danger.main,
    },
    header: {
      marginBottom: '1.5rem',
      alignItems: 'center',
    },
    cell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    legendColumn: {
      minWidth: 160,
      maxWidth: 160,
      paddingRight: 12,

      '& .legend-column__title': {
        paddingBottom: 17,
      },

      '& .label-box': {
        height: 192,
        marginTop: 55,
        '&:not(:first-of-type)': {
          marginTop: 35,
        },

        '& .base-row': {
          height: baseRowHeight,
        },
        '& .head-row': {
          height: headRowHeight,
        },
        '& .tail-row': {
          height: tailRowsHeight,
        },
        '& .label-text': {
          fontSize: 12,
          fontWeight: 400,
          color: 'rgba(0, 0, 0, 0.6)',
          flexGrow: 1,
        },
        '& .label-text--bold': {
          fontSize: 20,
          fontWeight: 500,
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    spread: {
      marginTop: 24,
      '& .spread__title': {
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: 12,
        fontWeight: 500,
        width: 94,
      },
      '& .spread__label': {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 14,
        fontWeight: 500,
      },
      '& .spread__row': {
        height: headRowHeight,
      },
    },
    spreadSubmercado: {
      marginTop: 30,
      '& .spread-submercado__right-box': {
        height: 118,
      },
    },
    spreadFonteEnergia: {
      '& .spread-fonte-energia__right-box': {
        height: 118,
      },
    },
    months: {
      '& .header-table': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
      '& .header-year-months': {
        display: 'flex',
      },
      '& .months-button': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      '& .month': {
        minWidth: 60,
        maxWidth: 60,
        height: 24,
        textAlign: 'center',
      },
      '& .month__text': {
        fontWeight: 500,
        fontSize: 16,
      },
    },
    tablePrice: {
      marginTop: 12,
      '&:not(:first-child)': {
        marginTop: 24,
      },
      '& td': {
        textAlign: 'center',
      },
      '& .base': {
        '& > td': {
          height: baseRowHeight,
          minWidth: 60,
          maxWidth: 60,
          boxSizing: 'border-box',
        },
      },

      '& .trimester, & .semester, & .year': {
        height: tailRowsHeight,
      },
      '& .bimester': {
        height: headRowHeight,
        '& td.bg-fill:nth-child(odd)': {
          backgroundColor: hexToRgba(theme.palette.primary.main, 0.6),
        },
        '& td.bg-fill:nth-child(even)': {
          backgroundColor: hexToRgba(theme.palette.primary.main, 0.8),
        },
      },
      '& .trimester': {
        '& td.bg-fill:nth-child(odd)': {
          backgroundColor: hexToRgba(theme.palette.primary.main, 0.6),
        },
        '& td.bg-fill:nth-child(even)': {
          backgroundColor: hexToRgba(theme.palette.primary.main, 0.8),
        },
      },
      '& .semester': {
        '& td.bg-fill:nth-child(odd)': {
          backgroundColor: hexToRgba(theme.palette.primary.main, 0.6),
        },
        '& td.bg-fill:nth-child(even)': {
          backgroundColor: hexToRgba(theme.palette.primary.main, 0.8),
        },
      },
      '& .year': {
        '& td.bg-fill:nth-child(odd)': {
          backgroundColor: hexToRgba(theme.palette.primary.main, 0.6),
        },
        '& td.bg-fill:nth-child(even)': {
          backgroundColor: hexToRgba(theme.palette.primary.main, 0.8),
        },
      },
    },
    tableSpread: {
      marginTop: 24,
      '& td': {
        textAlign: 'center',
      },
      '& .base': {
        '& > td': {
          height: 38,
          width: 60,
          minWidth: 60,
          maxWidth: 60,
          boxSizing: 'border-box',
        },
      },
    },
    horizontalScroll: {
      width: '100%',
      overflow: 'auto',
    },
  };
};
