import { useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../auth/authProvider';
import request from '../../services/network/request';

export function usePagination({ queryName, url, method = 'post', defaultFilters = {} }) {
  const { token } = useAuth();
  const filters = {
    filter: {},
    sort: {},
    page: 1,
    take: 10,
    options: {},
    ...defaultFilters,
  };

  const [parametros, setParametros] = useState({ ...filters });

  const resetFilter = () => {
    setParametros({ ...filters });
  };

  const setPage = page => {
    setParametros({
      ...parametros,
      page,
    });
  };

  const setTake = take => {
    setParametros({
      ...parametros,
      take,
      page: 1,
    });
  };

  const setFilter = (column, value) => {
    setParametros({
      ...parametros,
      page: 1,
      filter: {
        ...parametros.filter,
        [column]: value,
      },
    });
  };

  const setFilters = newFilters => {
    setParametros({
      ...parametros,
      page: 1,
      filter: {
        ...parametros.filter,
        ...newFilters,
      },
    });
  };

  const setSort = (column, value) => {
    setParametros({
      ...parametros,
      sort: {
        ...parametros.sort,
        [column]: value?.toUpperCase(),
      },
    });
  };
  const { data, error, isLoading, isFetching, refetch, isSuccess } = useQuery(
    [queryName, parametros],
    () => {
      const { filter, page, sort, take, options } = parametros;
      if (method === 'get') {
        const parsedSort = Object.entries(sort)
          .filter(([, value]) => value)
          .map(([key, value]) => `${key}:${value}`)
          .join();

        return request.get(url, {
          token,
          params: {
            page,
            take,
            sort: parsedSort,
            ...filter,
            ...options,
          },
        });
      } else {
        return request.post(
          url,
          {
            filters: filter,
            sort,
          },
          {
            token,
            params: {
              page,
              take,
              ...options,
            },
          },
        );
      }
    },
    { enabled: !!url },
  );

  return {
    setPaginationParameters: setParametros,
    setFilter,
    setFilters,
    setPage,
    setTake,
    setSort,
    parametros,
    data,
    error,
    isFetching,
    isLoading,
    refetch,
    resetFilter,
    isSuccess,
  };
}
