export default theme => ({
  root: {
    padding: theme.spacing(4),
    marginTop: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  item: {
    height: '100%',
  },
  card: {
    display: 'inline-block',
    height: 186,
    width: 166,
    margin: 12,
    cursor: 'pointer',
  },
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardIcon: {
    height: 48,
    width: 48,
  },
  cardText: {
    paddingTop: 18,
    textAlign: 'center',
  },
});
