import React from 'react';
import CrmIntegracao from './CRMIntegracao/CrmIntegracao';
import { Dashboard } from '../../layouts';
import TabPermissions from '../../components/TabPermissions/TabPermissions';

export const tabsIntegracao = Object.freeze({
  '/integracoes/crm-integracao': {
    component: <CrmIntegracao />,
    label: 'CRM INTEGRAÇÃO',
  },
});

function Integracao() {
  return (
    <Dashboard title="Integrações">
      <TabPermissions tabs={tabsIntegracao} />
    </Dashboard>
  );
}

export default Integracao;
