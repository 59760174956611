import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Divider, makeStyles, Paper, Typography, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import LoopIcon from '@material-ui/icons/Loop';
import AgioLine from './AgioLine';
import { useAgioForm } from '../hooks/useAgioForm';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';

const useStyles = makeStyles(theme => ({
  subtitle2: {
    color: theme.palette.common.muted,
    textTransform: 'uppercase',
  },
  success: {
    color: theme.palette.success.main,
  },
  muted: {
    color: theme.palette.common.muted,
  },
  primary: {
    color: theme.palette.primary.main,
  },
}));

function AgioForm(props) {
  const { errorVisibility, onSave, status, values, onChange, monthLabel } = props;

  const classes = useStyles(props);

  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  const { isSalvarEnabled, error, isFormValid, handleOnChange, changed, setChanged } = useAgioForm({
    status,
    values,
    onChange,
  });

  const [showFeedback, setShowFeedback] = useState(true);

  function getStatusDescription() {
    if (status === 'success') {
      return (
        <>
          <Box mr={1}>
            <CheckCircleIcon className={classes.success} />
          </Box>
          <Typography variant="caption" className={classes.success}>
            Salvo
          </Typography>
        </>
      );
    } else if (status === 'loading') {
      return (
        <>
          <Box mr={1}>
            <LoopIcon className={classes.primary} />
          </Box>
          <Typography variant="caption" className={classes.muted}>
            Salvando
          </Typography>
        </>
      );
    }
    return '';
  }

  return (
    <Paper>
      <form
        noValidate
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();

          if (isFormValid()) {
            onSave();
            setChanged(false);
            setShowFeedback(true);

            setTimeout(() => {
              setShowFeedback(false);
            }, 3000);
          }
        }}
      >
        <Box p={3}>
          <Box display="flex" flexDirection="row">
            <Box display="flex" alignItems="center" mr={2}>
              <Typography variant="subtitle1">Ágio curto prazo</Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" className={classes.subtitle2}>
                {monthLabel}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row" ml="auto">
              <Box display="flex" alignItems="center" marginRight={2} visibility={errorVisibility}>
                <Typography variant="caption" color="error">
                  Necessário salvar PLD Médio primeiro
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mr={2} visibility={!showFeedback ? 'hidden' : ''}>
                {!changed && getStatusDescription()}
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={!(isSalvarEnabled && editar)}
              >
                SALVAR
              </Button>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box p={3}>
          <Box display="flex" flexDirection="column">
            <AgioLine label="SE/CO" namePrefix="agioSECO" onChange={handleOnChange} values={values} error={error} />
          </Box>

          <Box display="flex" flexDirection="column" pt={2}>
            <AgioLine label="N" namePrefix="agioN" onChange={handleOnChange} values={values} error={error} />
          </Box>

          <Box display="flex" flexDirection="column" pt={2}>
            <AgioLine label="NE" namePrefix="agioNE" onChange={handleOnChange} values={values} error={error} />
          </Box>

          <Box display="flex" flexDirection="column" pt={2}>
            <AgioLine label="S" namePrefix="agioS" onChange={handleOnChange} values={values} error={error} />
          </Box>
        </Box>
      </form>
    </Paper>
  );
}

AgioForm.propTypes = {
  onSave: PropTypes.func,
  status: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.any,
  onChange: PropTypes.func,
  monthLabel: PropTypes.string,
  errorVisibility: PropTypes.oneOf(['hidden', 'visible']),
};

AgioForm.defaultProps = {
  onSave: () => {},
  status: '',
  values: {},
  onChange: () => {},
  monthLabel: '',
  errorVisibility: 'hidden',
};

export default AgioForm;
