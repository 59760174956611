import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, IconButton, Typography, Link } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import CampanhasModal from './AlterarCampanhaModal';

export function CampanhasTableRow(props) {
  const { clientesData, refetch, classes } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <TableRow key={clientesData?.id}>
        <TableCell className={classes.smallerTableCell}>{clientesData?.nome}</TableCell>
        <TableCell className={classes.smallerTableCell}>{clientesData?.redirecionamento}</TableCell>
        <TableCell className={classes.largerTableCell}>
          <Typography variant="body2">
            <Link href={clientesData?.presignedDesktop} target="_blank" rel="noopener noreferrer">
              {clientesData?.imgDesktop}
            </Link>
          </Typography>
        </TableCell>
        <TableCell className={classes.largerTableCell}>
          <Typography variant="body2">
            <Link href={clientesData?.presignedMobile} target="_blank" rel="noopener noreferrer">
              {clientesData?.imgMobile}
            </Link>
          </Typography>
        </TableCell>
        <TableCell className={classes.smallestTableCell}>{clientesData?.dataInicio}</TableCell>
        <TableCell className={classes.smallestTableCell}>{clientesData?.dataFim}</TableCell>
        <TableCell className={classes.smallestTableCell}>{clientesData?.status}</TableCell>
        <TableCell className={classes.smallestTableCell}>
          <IconButton onClick={() => setIsOpen(true)}>
            <Edit />
          </IconButton>
        </TableCell>
      </TableRow>
      <CampanhasModal clientesData={clientesData} refetch={refetch} open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
}

CampanhasTableRow.propTypes = {
  clientesData: PropTypes.shape({
    id: PropTypes.string,
    nome: PropTypes.string,
    redirecionamento: PropTypes.string,
    imgDesktop: PropTypes.string,
    imgMobile: PropTypes.string,
    dataInicio: PropTypes.string,
    dataFim: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
};
