import React from 'react';
import { NotificationSummaryParameters } from './components/NotificationSummaryParameters/NotificationSummaryParameters';
import { NotificationSummaryTable } from './components/NotificationSummaryTable/NotificationSummaryTable';
import { NotificationSummaryProvider } from './NotificationSummaryProvider';

export function NotificationSummary() {
  return (
    <NotificationSummaryProvider>
      <NotificationSummaryParameters />
      <NotificationSummaryTable />
    </NotificationSummaryProvider>
  );
}
