import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  makeStyles,
  TableCell,
  Box,
  Typography,
  TableBody,
  Grid,
  CircularProgress,
  TableFooter,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';
import { Portlet, PortletContent, ControlsTablePagination } from '../../../../../../components';
import styles from './NotificationSummaryTable.style';
import { useNotificationSummary } from '../../NotificationSummaryProvider';
import { NotificationSummaryTableRow } from './NotificationSummaryTableRow';

const useStyles = makeStyles(styles);

export function NotificationSummaryTable() {
  const { filteredNotificationSummary, isLoading, error, setTake, setPage, parameters } = useNotificationSummary();
  const classes = useStyles();
  const rootClassName = classNames(classes.root);

  if (isLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <Portlet className={rootClassName}>
        <PortletContent>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h6">Erro ao carregar dados.</Typography>
          </Grid>
        </PortletContent>
      </Portlet>
    );
  }

  if (!filteredNotificationSummary?.content || filteredNotificationSummary?.content?.length === 0) {
    return (
      <Portlet className={rootClassName}>
        <PortletContent>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h6">Nenhum registro encontrado.</Typography>
          </Grid>
        </PortletContent>
      </Portlet>
    );
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table className={classes.marginTopTable}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box>
                    <Typography variant="subtitle1">Notificação</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="subtitle1">Canal de envio</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="subtitle1">Provedor atual</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="subtitle1">ID do template</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="subtitle1">Quantidade de variáveis</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="subtitle1">Quantidade de envios com sucesso</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="subtitle1">Data do último envio</Typography>
                  </Box>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNotificationSummary?.content?.map(template => (
                <NotificationSummaryTableRow key={`${template.templateName}_${template.channel}`} template={template} />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <ControlsTablePagination
                  total={Number(filteredNotificationSummary?.total)}
                  page={parameters.page}
                  rowsPerPage={parameters.take}
                  onChangeRowsPerPage={e => setTake(e.target.value)}
                  onChangePage={(e, page) => setPage(page)}
                  labelRowsPerPage="Linhas por página"
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}
