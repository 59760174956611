import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ClientesParametrosHeader } from './ClientesParametrosHeader';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import { ClientesParametrosFilters } from './ClientesParametrosFilters';

export const ClientesParametros = props => {
  const { handleDownloadAgents, filters, setFilters, setSelectedFilter } = props;

  return (
    <Box mb={2}>
      <TableHeader>
        <ClientesParametrosHeader
          handleDownloadAgents={handleDownloadAgents}
          filters={filters}
          setFilters={setFilters}
        />
        <ClientesParametrosFilters filters={filters} setFilters={setFilters} setSelectedFilter={setSelectedFilter} />
      </TableHeader>
    </Box>
  );
};

ClientesParametros.propTypes = {
  handleDownloadAgents: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    distribuidora: PropTypes.string,
    dataFechamento: PropTypes.string,
    parceiro: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
};
