export default theme => ({
  root: { marginTop: '1.75rem' },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    cursor: 'pointer',
    height: '64px',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  failedRow: {
    backgroundColor: theme.palette.error.light, // Cor de fundo para falha
    '&:hover': {
      backgroundColor: theme.palette.error.main, // Cor de fundo ao passar o mouse
    },
  },
});
