import React from 'react';
import { TableRow, TableCell, Collapse, Box, Typography, Table, TableBody, TableHead } from '@material-ui/core';

export function ExpandableTableRow({ open, title, description, data }) {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1 }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {description}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Propriedade</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              Object.entries(data).map(([property, value]) => (
                <TableRow key={property}>
                  <TableCell>{property}</TableCell>
                  <TableCell>{JSON.stringify(value)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  );
}
