import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { ParceirosTableRowStyles } from '../Parceiros.style';
import MenuButton from './MenuButton';
import ModalEditarDados from './ModalEditarDados';

export function ParceirosTableRow(props) {
  const { invoicesData, refetch } = props;
  const useRowStyles = makeStyles(ParceirosTableRowStyles);
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <TableRow onClick={() => setOpen(!open)} className={classes.tableRowStyle} style={{ cursor: 'pointer' }}>
        <TableCell width="15%">
          <Typography variant="body2">{invoicesData?.partner?.fullName}</Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {invoicesData?.purchaseOrderNumber ? invoicesData?.purchaseOrderNumber : '--'}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {invoicesData?.status}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {invoicesData?.amount
              ? invoicesData?.amount?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : '--'}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {DateTime.fromISO(invoicesData?.referenceDate).toFormat('dd/MM/yyyy')}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {invoicesData?.issueDate ? DateTime.fromISO(invoicesData?.issueDate).toFormat('dd/MM/yyyy') : '--'}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Typography className={classes.tableCell} variant="body2">
            {invoicesData?.paymentDate ? DateTime.fromISO(invoicesData?.paymentDate).toFormat('dd/MM/yyyy') : '--'}
          </Typography>
        </TableCell>

        <TableCell className={classes.tableCell} onClick={event => event.stopPropagation()}>
          <MenuButton
            menuOptions={[
              {
                title: 'Editar',
                action: () => setModalOpen(!modalOpen),
              },
            ]}
          />
        </TableCell>
      </TableRow>

      <TableRow style={{ display: open ? 'contents' : 'none' }}>
        <TableCell colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" width="100%" className={classes.tableEmpresas} sx={{ gap: '14px' }}>
              <Typography
                align="left"
                variant="subtitle2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: '6px',
                  flex: 1,
                }}
              >
                Observação:
                <Typography component="span" variant="body2">
                  {invoicesData.comment}
                </Typography>
              </Typography>
              <Typography
                align="left"
                variant="subtitle2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: '6px',
                }}
              >
                Número da Nota:
                <Typography component="span" variant="body2" color="primary" style={{ fontWeight: '500' }}>
                  {invoicesData.invoiceNumber}
                </Typography>
              </Typography>
              <Typography
                align="left"
                variant="subtitle2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: '6px',
                }}
              >
                Entrada no sistema:
                <Typography component="span" variant="body2" color="primary" style={{ fontWeight: '500' }}>
                  {invoicesData?.uploadDate ? DateTime.fromISO(invoicesData?.uploadDate).toFormat('dd/MM/yyyy') : '--'}
                </Typography>
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <ModalEditarDados
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        id={invoicesData?.id}
        invoiceData={invoicesData}
        refetch={refetch}
      />
    </>
  );
}

ParceirosTableRow.propTypes = {
  invoicesData: PropTypes.shape({
    partner: PropTypes.object,
    purchaseOrderNumber: PropTypes.string,
    status: PropTypes.string,
    amount: PropTypes.string,
    referenceDate: PropTypes.string,
    issueDate: PropTypes.string,
    paymentDate: PropTypes.string,
    refetch: PropTypes.func.isRequired,
  }).isRequired,
};
