import React from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { TabsURL } from '../../helpers/enums';
import TabPermissions from '../../components/TabPermissions/TabPermissions';
import { Parceiros } from './components/Parceiros/Parceiros';
import { Clientes } from './components/Clientes/Clientes';
import { Campanhas } from './components/Campanhas/Campanhas';
import { CommunityErrors } from './components/Errors/Errors';

export const tabsComunidade = Object.freeze({
  [TabsURL.COMUNIDADE__PARCEIROS]: {
    component: <Parceiros />,
    label: 'PARCEIROS',
  },
  [TabsURL.COMUNIDADE__CLIENTES]: {
    component: <Clientes />,
    label: 'CLIENTES',
  },
  [TabsURL.COMUNIDADE__CAMPANHAS]: {
    component: <Campanhas />,
    label: 'CAMPANHAS',
  },

  [TabsURL.COMUNIDADE__BENEFITS_MGM_ERRORS]: {
    component: <CommunityErrors />,
    label: 'LOGS DE BENEFÍCIO MGM',
  },
});

export default function Comunidade() {
  return (
    <DashboardLayout title="Comunidade">
      <TabPermissions tabs={tabsComunidade} />
    </DashboardLayout>
  );
}
