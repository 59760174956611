export default theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
    },
    paper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '35px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
};
