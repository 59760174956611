import React, { useState } from 'react';
import { Paper, Typography, Box, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import styles from './GerenciadorFuncionalidade.styles';
import CsvUploadWithErrorFile from '../../../components/UploadCsv/CsvUploadWithErrorFile';
import { usePermissions } from '../../../hooks/usePermissions/usePermissions';
import FileDownloadArrayBuffer from '../../../helpers/FileDownloadArrayBuffer';

const useStyles = makeStyles(styles);

const handleExportExample = () => {
  const csvContent = 'email\n';
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  FileDownloadArrayBuffer.downloadFile(blob, 'exemplo-permissoes-funcionalidades');
};

function GerenciadorFuncionalidade() {
  const { getPermissions } = usePermissions();
  const editar = getPermissions();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [feature, setFeature] = useState('');

  return (
    <>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h6">Gerenciador de Funcionalidades</Typography>
            <Box display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
              <FormControl variant="outlined" style={{ minWidth: 200, marginRight: 16 }}>
                <InputLabel>Funcionalidade</InputLabel>
                <Select value={feature} onChange={e => setFeature(e.target.value)} label="Funcionalidade">
                  <MenuItem value="CREDIT_CARD_EXPERIENCE">EXPERIÊNCIA CARTÃO</MenuItem>
                </Select>
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CloudUploadIcon />}
                disabled={!editar || !feature}
                onClick={() => setOpenModal(true)}
                style={{ marginRight: 16 }}
              >
                Enviar CSV
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                disabled={!editar}
                startIcon={<CloudDownloadIcon />}
                onClick={handleExportExample}
              >
                Exportar Exemplo
              </Button>
            </Box>
          </div>
        </Paper>
      </Box>
      <CsvUploadWithErrorFile
        config={{
          data: { featureName: feature },
          url: 'feature-contact/import',
          alert: {
            init: 'Enviando CSV para adicionar permissões...',
            error: 'Erro ao enviar CSV. Verifique os dados do arquivo e tente novamente',
            success: 'CSV enviado com sucesso!',
          },
          queryCacheId: 'feature-contact',
          dialog: {
            title: 'Enviar CSV de permissões de funcionalidades para os contatos',
          },
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default GerenciadorFuncionalidade;
