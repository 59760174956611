import { Box, Button, InputAdornment, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TableHeader from '../../../theme/components/TableHeader/TableHeader';
import { useCRMIntegracao } from './CRMIntegracaoProvider';
import styles from './CRMIntegracaoTableParametros.styles';

function CRMIntegracaoTableParametros({ classes }) {
  const { setFilter, resetFilter } = useCRMIntegracao();

  const [inputDescricao, setInputDescricao] = useState('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilter({ description: inputDescricao });
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [inputDescricao, setFilter]);

  const resetInputAndFilters = () => {
    resetFilter();
    setInputDescricao('');
  };

  return (
    <TableHeader>
      <TableHeader.Header>
        <TableHeader.Header.Title>Busca & Filtros</TableHeader.Header.Title>
        <TableHeader.OptionsLeft>
          <TableHeader.Input>
            <Button color="primary" onClick={() => resetInputAndFilters()}>
              LIMPAR FILTROS
            </Button>
          </TableHeader.Input>
        </TableHeader.OptionsLeft>
      </TableHeader.Header>
      <TableHeader.Filters>
        <Box display="flex" alignItems="end">
          <TableHeader.Input>
            <TextField
              className={classes.filterInput}
              value={inputDescricao}
              id="standard-basic"
              label="Descrição"
              onChange={e => setInputDescricao(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className={classes.searchIcon} fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          </TableHeader.Input>
        </Box>
      </TableHeader.Filters>
    </TableHeader>
  );
}

CRMIntegracaoTableParametros.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(CRMIntegracaoTableParametros);
