import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClientesTableRowStyles } from '../Clientes.style';

export function ClientesTableRow(props) {
  const { clientesData } = props;
  const useRowStyles = makeStyles(ClientesTableRowStyles);
  const classes = useRowStyles();

  return (
    <>
      <TableRow>
        <TableCell width="12.5%">
          <Typography variant="body2">{clientesData?.nomeCliente}</Typography>
          <Typography>
            <Box display="flex" component="span" className={classes.orgLine}>
              <Box display="flex" alignItems="center" component="span">
                <Typography variant="caption" className={classes.cnpjCaption}>
                  {clientesData?.cpfCnpj}
                </Typography>
              </Box>
            </Box>
          </Typography>
        </TableCell>

        <TableCell width="12.5%">
          <Typography className={classes.tableCell} variant="body2">
            {clientesData?.distribuidora}
          </Typography>
        </TableCell>

        <TableCell width="12.5%">
          <Typography className={classes.tableCell} variant="body2">
            {clientesData?.valorComissao}
          </Typography>
        </TableCell>

        <TableCell width="12.5%">
          <Typography className={classes.tableCell} variant="body2">
            {clientesData?.valorPrimeiraParcela}
          </Typography>
        </TableCell>

        <TableCell width="12.5%">
          <Typography className={classes.tableCell} variant="body2">
            {clientesData?.dataFechamento}
          </Typography>
        </TableCell>

        <TableCell width="12.5%">
          <Typography className={classes.tableCell} variant="body2">
            {clientesData?.valorSegundaParcela}
          </Typography>
        </TableCell>

        <TableCell width="12.5%">
          <Typography className={classes.tableCell} variant="body2">
            {clientesData?.dataPagamentoPrimeiraParcela}
          </Typography>
        </TableCell>

        <TableCell width="12.5%">
          <Typography className={classes.tableCell} variant="body2">
            {clientesData?.nomeParceiro}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

ClientesTableRow.propTypes = {
  clientesData: PropTypes.shape({
    nomeCliente: PropTypes.string,
    cpfCnpj: PropTypes.string,
    distribuidora: PropTypes.string,
    valorComissao: PropTypes.string,
    valorPrimeiraParcela: PropTypes.string,
    dataPagamentoPrimeiraParcela: PropTypes.string,
    dataFechamento: PropTypes.string,
    nomeParceiro: PropTypes.string,
  }).isRequired,
};
