import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import request from '../../../../services/network/request';
import { useAuth } from '../../../../auth/authProvider';

const toDate = date => (date && DateTime.fromJSDate(date)?.isValid ? DateTime.fromJSDate(date) : null);

const defaultFilter = {
  templateName: 'all',
  channel: 'all',
  startDate: null,
  actualProvider: 'all',
};

const NotificationSummaryContext = createContext({
  filteredNotificationSummary: { content: [], total: 0 },
  uniqueTemplates: [],
  uniqueChannels: [],
  uniqueProviders: [],
  getNotificationSummary() {},
  isLoading: false,
  error: null,
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setTake() {},
  parameters: {
    filter: defaultFilter,
    page: 1,
    take: 10,
  },
});

export const NotificationSummaryProvider = ({ children }) => {
  const { token } = useAuth();
  const [allNotificationSummary, setAllNotificationSummary] = useState([]);
  const [filteredNotificationSummary, setFilteredNotificationSummary] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uniqueTemplates, setUniqueTemplates] = useState([]);
  const [uniqueProviders, setUniqueProviders] = useState([]);
  const [uniqueChannels, setUniqueChannels] = useState([]);
  const [countRecord, setCountRecord] = useState(0);
  const [parameters, setParameters] = useState({
    filter: defaultFilter,
    page: 1,
    take: 10,
  });

  const setUniqueOptions = (response, key, setState) => {
    const uniqueItems = new Set();
    response.forEach(element => {
      uniqueItems.add(element[key]);
    });
    setState([...uniqueItems]);
  };

  const setTemplateOptions = response => {
    const distinctTemplates = [];
    response.forEach(element => {
      if (!distinctTemplates.find(t => t.templateName === element.templateName)) {
        distinctTemplates.push({
          templateName: element.templateName,
          templateLabel: element.templateLabel,
        });
      }
    });
    setUniqueTemplates(distinctTemplates);
  };

  const setChannelOptions = response => {
    setUniqueOptions(response, 'channel', setUniqueChannels);
  };

  const setProviderOptions = response => {
    setUniqueOptions(response, 'actualProvider', setUniqueProviders);
  };

  const applyLocalFilter = templates => {
    const { filter } = parameters;
    const filtered = templates.filter(
      n =>
        (filter.templateName === 'all' || n.templateName === filter.templateName) &&
        (filter.channel === 'all' || n.channel === filter.channel) &&
        (filter.actualProvider === 'all' || n.actualProvider === filter.actualProvider),
    );

    setCountRecord(filtered.length);

    setFilteredNotificationSummary(
      filtered.slice((parameters.page - 1) * parameters.take, parameters.page * parameters.take),
    );
  };

  const initializeParameters = initialData => {
    setCountRecord(initialData?.length);
    setAllNotificationSummary(initialData);
    applyLocalFilter(initialData);
    setTemplateOptions(initialData);
    setChannelOptions(initialData);
    setProviderOptions(initialData);
  };

  const getNotificationSummary = async ({ startDate = null, endDate = null } = {}) => {
    const queryParams = new URLSearchParams();
    if (startDate) {
      queryParams.append('startDate', startDate);
    }
    if (endDate) {
      queryParams.append('endDate', endDate);
    }

    try {
      setIsLoading(true);
      setError(null);
      const response = await request.get(`/notification/customers/summary?${queryParams.toString()}`, { token });
      initializeParameters(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err);
    }
  };

  const setFilter = async filters => {
    const { startDate, endDate } = filters;

    const formattedFilters = {
      startDate: toDate(startDate),
      endDate: toDate(endDate),
    };

    await getNotificationSummary(formattedFilters);

    setParameters(prev => ({
      ...prev,
      filter: { ...prev.filter, ...filters },
      page: 1,
    }));
  };

  const resetFilter = async () => {
    if (parameters.filter.startDate !== null || parameters.filter.endDate !== null) {
      await getNotificationSummary();
    }
    setParameters({ ...parameters, filter: defaultFilter });
  };

  const setTake = take => {
    setParameters({ ...parameters, take, page: 1 });
  };

  const setPage = page => {
    setParameters({ ...parameters, page });
  };

  useMemo(() => {
    applyLocalFilter(allNotificationSummary);
  }, [parameters]);

  useEffect(() => {
    getNotificationSummary();
  }, []);

  return (
    <NotificationSummaryContext.Provider
      value={{
        filteredNotificationSummary: {
          content: filteredNotificationSummary,
          total: countRecord,
        },
        isLoading,
        error,
        parameters,
        getNotificationSummary,
        uniqueTemplates,
        uniqueChannels,
        uniqueProviders,
        setPage,
        setTake,
        setFilter,
        resetFilter,
      }}
    >
      {children}
    </NotificationSummaryContext.Provider>
  );
};

export const useNotificationSummary = () => useContext(NotificationSummaryContext);

NotificationSummaryProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
