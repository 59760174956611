import React, { useState } from 'react';
import { TableRow, TableCell, Box, Typography, Collapse, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { formatDate } from '../../../../../BaseUsuarios/Consumidores/utils/date';
import styles from './NotificationSummaryTable.style';
import { NotificationSummaryTableRowDetail } from './NotificationSummaryTableRowDetail';

const useStyles = makeStyles(styles);

export function NotificationSummaryTableRow({ template }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow onClick={() => setOpen(!open)} className={classes.tableRow}>
        <TableCell>
          <Box>
            <Typography variant="subtitle1">{template?.templateLabel}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="subtitle1">{template?.channel}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="subtitle1">{template?.actualProvider}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="subtitle1">{template?.actualTemplateId}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="subtitle1">{template?.variableCount}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="subtitle1">{template?.successCount}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="subtitle1">{formatDate(template.lastSuccessfulSentAt, 'dd/MM/yyyy')}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>{open ? <ExpandLess /> : <ExpandMore />}</Box>
        </TableCell>
      </TableRow>
      <TableRow style={{ display: open ? 'contents' : 'none' }}>
        <TableCell colSpan={8} style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <NotificationSummaryTableRowDetail template={template} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
