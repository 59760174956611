export default theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  smallestTableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '7vw',
    color: theme.palette.text.disabled,
  },
  smallerTableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '10vw',
    color: theme.palette.text.disabled,
  },
  largerTableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '18vw',
    color: theme.palette.text.disabled,
  },
});
