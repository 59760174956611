import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { StatusCampanha } from '../../../../../helpers/enums';
import request from '../../../../../services/network/request';
import { useAuth } from '../../../../../auth/authProvider';

const InserirCampanhaModal = ({ open, onClose, refetch }) => {
  const [formData, setFormData] = useState({
    nome: '',
    redirecionamento: '',
    imgDesktop: null,
    imgMobile: null,
    dataInicio: '',
    dataFim: '',
    status: StatusCampanha.Inativa,
  });

  const resetFormData = () => {
    setFormData({
      nome: '',
      redirecionamento: '',
      imgDesktop: null,
      imgMobile: null,
      dataInicio: '',
      dataFim: '',
      status: StatusCampanha.Inativa,
    });
  };

  const { token } = useAuth();
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (event, field) => {
    const file = event.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        [field]: file,
      }));
    }
  };

  const [onSubmit, { isLoading }] = useMutation(
    dadosForm =>
      request.post(`/backstage/comunidade/campanhas`, dadosForm, {
        contentType: 'multipart/form-data',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    {
      onSuccess: () => {
        setSnackbar({ open: true, message: 'Campanha inserida com sucesso!' });
        setTimeout(() => {
          onClose();
          refetch();
          resetFormData();
        }, 2000);
      },
      onError: error => {
        setSnackbar({ open: true, message: error || 'Erro ao inserir campanha' });
      },
    },
  );

  const canProceed = () => {
    const { nome, imgDesktop, dataInicio, status, imgMobile } = formData;
    return nome && imgDesktop && dataInicio && status && imgMobile;
  };

  const handleSubmit = () => {
    if (!canProceed()) {
      setSnackbar({ open: true, message: 'Preencha todos os campos obrigatórios' });
      return;
    }

    const formCampanha = new FormData();

    Object.keys(formData).forEach(key => {
      if (formData[key]) {
        formCampanha.append(key, formData[key]);
      }
    });

    onSubmit(formCampanha);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            maxWidth: 500,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Inserir Campanha
          </Typography>

          <TextField
            label="Nome"
            fullWidth
            required
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Redirecionamento (Opcional)"
            fullWidth
            name="redirecionamento"
            value={formData.redirecionamento}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />

          <TextField
            label="Data de Início"
            type="date"
            fullWidth
            required
            name="dataInicio"
            value={formData.dataInicio}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />

          <TextField
            label="Data de Fim (Opcional)"
            type="date"
            fullWidth
            name="dataFim"
            value={formData.dataFim}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />

          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Status</InputLabel>
            <Select label="Status" name="status" value={formData.status} onChange={handleChange} fullWidth>
              <MenuItem value={StatusCampanha.Ativa}>Ativa</MenuItem>
              <MenuItem value={StatusCampanha.Inativa}>Inativa</MenuItem>
            </Select>
          </FormControl>

          <Box display="flex" flexDirection="column">
            <Button variant="outlined" color="primary" component="label">
              <input
                hidden
                type="file"
                onChange={e => handleImageChange(e, 'imgDesktop')}
                accept={['image/jpeg', 'image/jpg', 'image/png'].join(', ')}
                style={{ marginBottom: 16, width: '100%' }}
              />
              {`${formData?.imgDesktop?.name || 'Selecione o banner desktop'} `}
            </Button>
            <br />

            <Button variant="outlined" color="primary" component="label">
              <input
                hidden
                type="file"
                onChange={e => handleImageChange(e, 'imgMobile')}
                accept={['image/jpeg', 'image/jpg', 'image/png'].join(', ')}
                style={{ marginBottom: 16, width: '100%' }}
              />
              {`${formData?.imgMobile?.name || 'Selecione o banner mobile '} `}
            </Button>
            <br />
            <Button onClick={handleSubmit} color="primary" disabled={isLoading || !canProceed()} variant="contained">
              {isLoading ? <CircularProgress size="14px" /> : 'Enviar'}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        message={snackbar.message}
        onClose={() => setSnackbar({ open: false, message: '' })}
      />
    </>
  );
};

export default InserirCampanhaModal;
