export default theme => ({
  root: {},
  docName: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    opacity: 0.6,
  },
  selected: {
    backgroundColor: theme.palette.primary.serena,
  },
  noSelected: {
    backgroundColor: theme.palette.common.white,
  },
  radioControlLabel: {
    marginRight: theme.spacing(4),
  },
});
