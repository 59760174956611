import React from 'react';
import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  Tooltip,
} from '@material-ui/core';

export function NotificationFilterExpandableTableRow({ open, title, description, data }) {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1 }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {description}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Propriedade</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              Object.entries(data).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>
                    {value && (
                      <Tooltip title={value} arrow>
                        <Typography variant="subtitle1">
                          {value.length > 200 ? `${value.slice(0, 200)}...` : value}
                        </Typography>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  );
}
