import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import request from '../../../../services/network/request';
import { useAuth } from '../../../../auth/authProvider';
import CampanhasTable from './CampanhasTable/CampanhasTable';
import InserirCampanhaModal from './CampanhasTable/InserirCampanhaModal';

const Campanhas = () => {
  const { token } = useAuth();
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const { isFetching: isLoading, refetch } = useQuery(
    ['fetch-campanhas-bs'],
    () => {
      return request.get('backstage/comunidade/campanhas', { token });
    },
    {
      onSuccess: data => {
        setTableData([...data.content]);
        setTotal(data.total);
      },
    },
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          color="primary"
          onClick={() => setIsOpen(prev => !prev)}
          variant="contained"
          startIcon={<CloudUpload />}
        >
          INSERIR CAMPANHA
        </Button>
      </Box>
      {!isLoading && tableData.length > 0 && <CampanhasTable data={tableData} total={total} refetch={refetch} />}
      <InserirCampanhaModal refetch={refetch} open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export { Campanhas };
