import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableFooter,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { useQueryCache } from 'react-query';
import { useNotificationFilter } from './NotificationFilterProvider';
import { NotificationFilterTableHead } from './components/NotificationFilterTableHead';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../../components';
import { NotificationFilterTableRow } from './components/NotificationFilterTableRow';
import { FilterForm } from './components/FilterForm';
import SnackbarAlert from '../../../../components/SnackbarAlert/SnackbarAlert';

export function NotificationFilter() {
  const {
    notificationsWithTotalCount,
    isFetchingNotificationsWithTotalCount,
    notificationFilterOptions,
    isFeatchingNotificationFiltersOptions,
    isFeatchingRetryNotification,
    retrySendNotification,
    parametros,
    setPage,
    setFilter,
    alert,
    setAlert,
  } = useNotificationFilter();
  const { statusOptions, channelOptions, templateNameOptions } = notificationFilterOptions || {};
  const [customerNotification, count] = notificationsWithTotalCount || [];
  const [selected, setSelected] = useState([]);
  const queryCache = useQueryCache();

  useEffect(() => {
    if (parametros) {
      setSelected([]);
    }
  }, [parametros]);

  if (isFetchingNotificationsWithTotalCount || isFeatchingNotificationFiltersOptions || isFeatchingRetryNotification) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={1}>
        <CircularProgress />
        {isFeatchingRetryNotification && (
          <Typography variant="h6" gutterBottom>
            Aguarde! Estamos processando...
          </Typography>
        )}
      </Box>
    );
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = customerNotification
        .filter(({ status }) => status === 'failed')
        .map(({ publicId }) => publicId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = id => {
    setSelected(prevSelected =>
      prevSelected.includes(id) ? prevSelected.filter(item => item !== id) : [...prevSelected, id],
    );
  };

  const mappedCustomerNotifications =
    customerNotification?.map(item => {
      return {
        ...item,
        status: statusOptions.find(s => s.value === item.status)?.label || item.status,
        channel: channelOptions.find(c => c.value === item.channel)?.label || item.channel,
        templateName: templateNameOptions.find(t => t.value === item.templateName)?.label || item.templateName,
      };
    }) || [];

  return (
    <div>
      <Portlet>
        <PortletContent>
          <FilterForm
            filterOptions={notificationFilterOptions}
            handleRetry={retrySendNotification}
            selected={selected}
            handleUpdate={() => queryCache.invalidateQueries('fetch-notifications')}
          />
        </PortletContent>
      </Portlet>
      <Portlet>
        <PortletContent>
          <TableContainer>
            <Typography variant="h6" gutterBottom>
              Listagem das Notificações
            </Typography>

            <Table>
              <NotificationFilterTableHead
                onSelectAllClick={handleSelectAllClick}
                numSelected={selected.length}
                rowCount={customerNotification.filter(({ status }) => status === 'failed')?.length}
              />

              <TableBody>
                {mappedCustomerNotifications.map(mappedCustomerNotification => {
                  const isItemSelected = selected.includes(mappedCustomerNotification.publicId);
                  return (
                    <NotificationFilterTableRow
                      data={mappedCustomerNotification}
                      isItemSelected={isItemSelected}
                      handleClick={handleClick}
                      key={mappedCustomerNotification.publicId}
                    />
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <ControlsTablePagination
                    total={count}
                    page={parametros.page}
                    rowsPerPage={parametros.filter.limit}
                    onChangeRowsPerPage={e => setFilter('limit', e.target.value)}
                    onChangePage={(e, page) => setPage(page)}
                    labelRowsPerPage="Linhas por página"
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </PortletContent>
      </Portlet>

      <SnackbarAlert
        show={alert.show}
        message={alert.message}
        onClose={() => setAlert({ show: false, message: null })}
        severity={alert.severity}
      />
    </div>
  );
}
