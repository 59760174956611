export default theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      paddingTop: '20px',
      paddingBottom: '18px',
      paddingLeft: '24px',
      width: '296px',
      height: '172px',
    },
    textoVariacao: {
      color: 'rgba(0, 0, 0, 0.6)',
      marginTop: '15px',
      marginRight: '14px',
      fontSize: '14px',
      letterSpacing: '0.25px',
      lineHeight: '20px',
    },
    buttons: {
      display: 'flex',
      marginTop: '15px',
      marginLeft: '0px',
      '& button': {
        fontSize: '14px',
        color: theme.palette.primary.main,
      },
    },
  };
};
