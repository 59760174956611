import React from 'react';
import { CRMIntegracaoProvider } from './CRMIntegracaoProvider';
import CRMIntegracaoTable from './CRMIntegracaoTable';
import CRMIntegracaoTableParametros from './CRMIntegracaoTableParametros';

function CrmIntegracao() {
  return (
    <CRMIntegracaoProvider>
      <CRMIntegracaoTableParametros />
      <CRMIntegracaoTable />
    </CRMIntegracaoProvider>
  );
}

export default CrmIntegracao;
