import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox, Typography, IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { NotificationFilterExpandableTableRow } from './NotificationFilterExpandableTableRow';

const dateConfig = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export function NotificationFilterTableRow({
  data: { templateName, channel, recipient, sentAt, status, publicId, metadata, errorMessage, updatedAt, createdAt },
  isItemSelected,
  handleClick,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <Checkbox
            onClick={() => handleClick(publicId)}
            color="primary"
            checked={isItemSelected}
            disabled={status !== 'Erro'}
            inputProps={{
              'aria-labelledby': publicId,
            }}
          />
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{status}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{templateName}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{channel}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{metadata.consumerUnitCode}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{recipient.telephone}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{recipient.email}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">
            {sentAt ? DateTime.fromISO(sentAt).toLocaleString(dateConfig) : ''}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">
            {createdAt ? DateTime.fromISO(createdAt).toLocaleString(dateConfig) : ''}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">
            {updatedAt ? DateTime.fromISO(updatedAt).toLocaleString(dateConfig) : ''}
          </Typography>
        </TableCell>

        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow style={{ display: open ? 'contents' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        <TableCell colSpan={12} style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          <div style={{ marginBottom: 10 }}>
            <NotificationFilterExpandableTableRow
              title="Identificadores"
              description="Parâmetros utilizados para identificar informações gerais sobre o envio"
              data={metadata}
              open={open}
            />
          </div>
          <NotificationFilterExpandableTableRow
            title="Error"
            description="Parâmetros utilizados para identificar informações gerais sobre o erro ocorrido"
            data={{ error: errorMessage }}
            open={open}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

NotificationFilterTableRow.propTypes = {
  data: PropTypes.shape({
    templateName: PropTypes.string.isRequired,
    consumerUnitCode: PropTypes.string,
    channel: PropTypes.string.isRequired,
    recipient: PropTypes.object,
    sentAt: PropTypes.string,
    status: PropTypes.string.isRequired,
    publicId: PropTypes.string.isRequired,
    metadata: PropTypes.object,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,

  isItemSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};
