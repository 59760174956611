import palette from '../../../../../theme/palette';

export default {
  dateInput: {
    '&:first-child': {
      marginRight: '1rem',
    },
  },

  divider: {
    marginTop: '0.5rem',
    marginBottom: '0.969rem',
  },

  infoDiv: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    minWidth: '6rem',
  },

  infoLabel: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: '0.025rem',
  },

  infoValue: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: '400',
    letterSpacing: '0.031rem',
    overflowWrap: 'break-word',
    color: palette.primary.main,
  },

  cardInfoTitle: { marginBottom: '1rem' },

  cardInfoDiv: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
  },

  divEmpty: {
    height: '18rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  divEmptyText: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0.011rem',
    color: 'rgba(0, 0, 0, 0.38)',
  },
};
