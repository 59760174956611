import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import palette from '../../../theme/palette';

function DownloadIcon(props) {
  const { color, className } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={cls(color, className)}>
      <path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" fill={palette.primary.main} />
    </svg>
  );
}

DownloadIcon.defaultProps = {
  color: 'text-brand-primary',
  className: '',
};

DownloadIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default DownloadIcon;
