import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableRow, TableFooter, Typography, Button, Box } from '@material-ui/core';
import { useQueryCache } from 'react-query';
import { useDistributedQueueTracker } from './DistributedQueueTrackerProvider';
import { EnhancedTableHead } from './components/EnhancedTableHead';
import { FilterForm } from './components/FilterForm';
import Loading from '../../../components/Loading/Loading';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../components';
import EnhancedTableRow from './components/EnhancedTableRow';
import SnackbarAlert from '../../../components/SnackbarAlert/SnackbarAlert';
import { usePermissions } from '../../../hooks/usePermissions/usePermissions';

export function DistributedQueueTracker() {
  const {
    trackingNotificationsWithTotalCount,
    isFetchingTrackingNotificationsWithTotalCount,
    trackingNotificationFilterOptions,
    isFeatchingTrackingNotificationFilterOptions,
    retryTrackingNotification,
    parametros,
    setPage,
    setFilter,
    alert,
    setAlert,
  } = useDistributedQueueTracker();
  const queryCache = useQueryCache();
  const { processes, statuses, channels, templateNames } = trackingNotificationFilterOptions || {};
  const [trackingNotifications, count] = trackingNotificationsWithTotalCount || [];
  const [selected, setSelected] = useState([]);
  const { getPermissions } = usePermissions();
  const editar = getPermissions();

  useEffect(() => {
    if (parametros) {
      setSelected([]);
    }
  }, [parametros]);

  if (isFetchingTrackingNotificationsWithTotalCount || isFeatchingTrackingNotificationFilterOptions) {
    return <Loading />;
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = trackingNotifications
        .filter(({ status }) => status === 'error')
        .map(({ publicId }) => publicId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = id => {
    setSelected(prevSelected =>
      prevSelected.includes(id) ? prevSelected.filter(item => item !== id) : [...prevSelected, id],
    );
  };

  const mappedTrackingNotifications =
    trackingNotifications?.map(item => {
      return {
        ...item,
        process: processes.find(p => p.value === item.process)?.label || item.process,
        status: statuses.find(s => s.value === item.status)?.label || item.status,
        channel: channels.find(c => c.value === item.channel)?.label || item.channel,
        templateName: templateNames.find(t => t.value === item.templateName)?.label || item.templateName,
      };
    }) || [];

  return (
    <div>
      <Portlet>
        <PortletContent>
          <FilterForm
            filterOptions={trackingNotificationFilterOptions}
            handleUpdate={() => queryCache.invalidateQueries('fetch-tracking-notification')}
          />
        </PortletContent>
      </Portlet>
      <Portlet>
        <PortletContent>
          <TableContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box textAlign="left">
                <Typography variant="h6" gutterBottom>
                  Listagem dos processos
                </Typography>

                <Typography variant="subtitle2" gutterBottom>
                  Segue a lista com todos os processos que emitem notificações para os clientes
                </Typography>
              </Box>

              <Button
                variant="outlined"
                onClick={() => retryTrackingNotification(selected)}
                disabled={!editar || !selected.length}
              >
                Reenviar Processos
              </Button>
            </Box>

            <Table>
              <EnhancedTableHead
                onSelectAllClick={handleSelectAllClick}
                numSelected={selected.length}
                rowCount={trackingNotifications.filter(({ status }) => status === 'error')?.length}
              />

              <TableBody>
                {mappedTrackingNotifications.map(mappedTrackingNotification => {
                  const isItemSelected = selected.includes(mappedTrackingNotification.publicId);
                  return (
                    <EnhancedTableRow
                      key={mappedTrackingNotification.publicId}
                      data={mappedTrackingNotification}
                      isItemSelected={isItemSelected}
                      handleClick={handleClick}
                    />
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <ControlsTablePagination
                    total={count}
                    page={parametros.page}
                    rowsPerPage={parametros?.filter.limit}
                    onChangeRowsPerPage={e => setFilter('limit', e.target.value)}
                    onChangePage={(e, page) => setPage(page)}
                    labelRowsPerPage="Linhas por página"
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </PortletContent>
      </Portlet>

      <SnackbarAlert
        show={alert.show}
        message={alert.message}
        onClose={() => setAlert({ show: false, message: null })}
        severity={alert.severity}
      />
    </div>
  );
}
