import React from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { TabsURL } from '../../helpers/enums';
import { NotificationFilterProvider } from './Notification/NotificationFilter/NotificationFilterProvider';
import { NotificacaoResumo } from './NotificacaoResumo/NotificacaoResumo';
import { NotificationFilter } from './Notification/NotificationFilter/NotificationFilter';
import { DistributedQueueTracker } from './DistributedQueueTracker/DistributedQueueTracker';
import { DistributedQueueTrackerProvider } from './DistributedQueueTracker/DistributedQueueTrackerProvider';
import TabPermissions from '../../components/TabPermissions/TabPermissions';

export const tabsComunicacoes = Object.freeze({
  [TabsURL.COMUNICACOES__NOTIFICACAO_RESUMO]: {
    component: <NotificacaoResumo />,
    label: 'NOTIFICAÇÕES - RESUMO',
  },
  [TabsURL.COMUNICACOES__NOTIFICACAO_FILTER]: {
    component: (
      <NotificationFilterProvider>
        <NotificationFilter />
      </NotificationFilterProvider>
    ),
    label: 'NOTIFICAÇÕES',
  },
  [TabsURL.COMUNICACOES__MONITORAMENTO_NOTIFICACOES_GD]: {
    component: (
      <DistributedQueueTrackerProvider>
        <DistributedQueueTracker />
      </DistributedQueueTrackerProvider>
    ),
    label: 'MONITORAMENTO NOTIFICAÇÕES GD',
  },
});

function Comunicacoes() {
  return (
    <DashboardLayout title="Comunicações">
      <TabPermissions tabs={tabsComunicacoes} />
    </DashboardLayout>
  );
}

export default Comunicacoes;
