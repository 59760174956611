import { formatCnpj } from '@brazilian-utils/formatters';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ArrowDownwardOutlined as DownloadIcon } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { formatCelular, formatVolume } from '@omega-energia/utilities';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { usePresignedDownload } from '@omega-energia/react';
import { ControlsTablePagination, Portlet, PortletContent } from '../../../../../components';
import TableColumnSortButton from '../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';
import { useIrec } from '../../IrecsProvider';
import styles from './IrecsTable.styles';
import ContentStringFormatter from '../../../../../helpers/ContentStringFormatter';

function IrecsTable({ classes }) {
  const { irecs, parametros, setSort, isLoading, error, setPage, setTake } = useIrec();
  const rootClassName = classNames(classes.root);
  const intl = useIntl();
  const { registerFile } = usePresignedDownload();

  function handleContratoDownload(path) {
    registerFile(path);
  }

  const formatPeriodo = (dataInicio, dataFim) => {
    const inicioDateTime = DateTime.fromISO(dataInicio);
    const fimDateTime = DateTime.fromISO(dataFim);

    return (
      <>
        Jan {inicioDateTime.get('year')} - <br /> Dez {fimDateTime.minus({ day: 1 }).get('year')}
      </>
    );
  };

  const formatCadastro = createdAt => {
    return (
      <TableCell className={classes.tableCell} width="10%">
        <div className={classes.tableCellInner}>
          <Box display="flex" flexDirection="column">
            <Typography variant="body2">{DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)}</Typography>
            <Typography variant="caption">{DateTime.fromISO(createdAt).toFormat('H:mm')}</Typography>
          </Box>
        </div>
      </TableCell>
    );
  };
  const formatCompra = isContratado => {
    if (isContratado) {
      return 'Realizada';
    }
    return 'Não Realizada';
  };

  if (error) {
    return <Typography variant="h6">{error.message}</Typography>;
  }

  if (!isLoading && Array.isArray(irecs) && irecs.length === 0) {
    return <Typography variant="h6">There are no Irecs</Typography>;
  }

  if (isLoading || !irecs) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" width="15%">
                  <Typography variant="subtitle1">
                    Organização
                    <TableColumnSortButton
                      value={parametros.sort.razaoSocial || ''}
                      onChange={sort => setSort('razaoSocial', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="left" width="20%">
                  <Typography variant="subtitle1">
                    Contato
                    <TableColumnSortButton
                      value={parametros.sort.email || ''}
                      onChange={sort => setSort('email', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" width="12%">
                  <Typography variant="subtitle1">
                    Volume
                    <TableColumnSortButton
                      value={parametros.sort.volume || ''}
                      onChange={sort => setSort('volume', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" width="12%">
                  <Typography variant="subtitle1">
                    Período
                    <TableColumnSortButton
                      value={parametros.sort.periodoAno || ''}
                      onChange={sort => setSort('periodoAno', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" width="12%">
                  <Typography variant="subtitle1">
                    Cadastro
                    <TableColumnSortButton
                      value={parametros.sort.createdAt || ''}
                      onChange={sort => setSort('createdAt', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" width="15%">
                  <Typography variant="subtitle1">
                    Contratação
                    <TableColumnSortButton
                      value={parametros.sort.isContratado || ''}
                      onChange={sort => setSort('isContratado', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center" width="15%">
                  <Typography variant="subtitle1">
                    Preço Total
                    <TableColumnSortButton
                      value={parametros.sort.valorTotal || ''}
                      onChange={sort => setSort('valorTotal', sort)}
                    />
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {irecs?.content?.map(irec => (
                <TableRow className={classes.tableRow} key={irec.id}>
                  <TableCell className={classes.tableCell} width="10%" title={irec.contato.razaoSocial}>
                    <div className={classes.tableCellInner}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body2">{ContentStringFormatter.clip(irec.contato.razaoSocial)}</Typography>
                        <Typography variant="caption">{formatCnpj(irec.contato.cnpj)}</Typography>
                      </Box>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell} width="10%">
                    <div className={classes.tableCellInner}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body2">{ContentStringFormatter.breakLine(irec.contato.email)}</Typography>
                        <Typography variant="caption">{formatCelular(irec.contato.celular)}</Typography>
                      </Box>
                    </div>
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Box display="flex" flexDirection="row" mr="20px" justifyContent="center">
                      <Box>
                        <Typography variant="body2">
                          {formatVolume(irec.isFuturo ? irec?.volumeReferenciaMensal : irec.volume)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography color="textSecondary" variant="body2">
                          MWh
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box display="flex" flexDirection="row" mr="20px" justifyContent="center">
                      <Typography>{formatPeriodo(irec.dataInicio, irec.dataFim)}</Typography>
                    </Box>
                  </TableCell>
                  {formatCadastro(irec.createdAt)}
                  <TableCell className={classes.tableCell}>
                    <Box display="flex" flexDirection="row" mr="20px" justifyContent="center">
                      <Typography variant="body2">{formatCompra(irec.isContratado)}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box display="flex" flexDirection="row" mr="20px" justifyContent="center">
                      <Typography variant="body2">
                        {intl.formatNumber(irec.valorTotal, {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box display="flex" flexDirection="row" mr="20px" justifyContent="center">
                      {irec.pathArquivoContrato && (
                        <Button onClick={() => handleContratoDownload(irec.pathArquivoContrato)}>
                          <DownloadIcon />
                        </Button>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <ControlsTablePagination
                  total={irecs?.total}
                  page={parametros.page}
                  rowsPerPage={parametros.take}
                  onChangeRowsPerPage={e => setTake(e.target.value)}
                  onChangePage={(e, page) => setPage(page)}
                  labelRowsPerPage="Linhas por página"
                />
              </TableRow>
            </TableFooter>
          </Table>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
}

IrecsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IrecsTable);
