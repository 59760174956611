import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Portlet, PortletContent } from '../../../../../components';
import ErrorsParametrosTablePagination from './ErrorsParametrosTablePagination';
import { ErrorsTableRow } from './ErrorsTableRow';
import { ErrorsTableRowStyles } from '../Errors.style';
import TableColumnSortButton from '../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';

const useStyles = makeStyles(ErrorsTableRowStyles);

export default function ErrorsParametrosTable(props) {
  const classes = useStyles(props);
  const {
    data: errors,
    total,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    setSorts,
    sorts,
    refetch,
  } = props;

  const rootClassName = cls(classes.root);

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <Table width="100%" className={classes.mainTable}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.rowShadow}>
              <TableCell className={classes.tableCell}>
                <Typography variant="body2">ID do Indicador</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">ID do Indicado</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">Mensagem de Erro</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">Funcionalidade</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">Tipo de Erro</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">Status</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2" className={classes.flexTypography}>
                  Data de Ocorrência{' '}
                  <TableColumnSortButton
                    value={sorts?.createdAt || ''}
                    onChange={sort => setSorts({ createdAt: sort })}
                  />
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">ErrorJson</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={classes.tableBody}>
            {!errors && (
              <TableRow width="100%" className={classes.tr}>
                <TableCell className={classes.tableCell} width="100%">
                  Ops. Não encontramos nada.
                </TableCell>
              </TableRow>
            )}
            {errors.map(row => {
              return <ErrorsTableRow key={row.id} errorsData={row} classes={classes} refetch={refetch} />;
            })}
          </TableBody>
        </Table>
        <TableFooter className={classes.tableFooter}>
          <TableRow>
            <TablePagination
              count={total}
              page={currentPage - 1}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Registros por página"
              onPageChange={() => {}}
              ActionsComponent={() => (
                <ErrorsParametrosTablePagination
                  total={total}
                  onChangePage={handleChangePage}
                  page={currentPage}
                  rowsPerPage={rowsPerPage}
                  refetch={refetch}
                />
              )}
            />
          </TableRow>
        </TableFooter>
      </PortletContent>
    </Portlet>
  );
}

ErrorsParametrosTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};
