import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../auth/authProvider';
import request from '../../services/network/request';

const commonPagination = {
  page: 1,
  take: 10,
  options: {},
};

export function usePaginationClientSide({ queryName, url, defaultSort = {}, defaultPagination = commonPagination }) {
  const { token } = useAuth();

  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({ ...defaultSort });
  const [pagination, setPagination] = useState({ ...defaultPagination });
  const [allData, setAllData] = useState([]);

  const resetFilter = () => {
    setFilters({});
    setSort({ ...defaultSort });
  };

  const setPage = page => {
    setPagination(prev => ({
      ...prev,
      page,
    }));
  };

  const setTake = take => {
    setPagination(prev => ({
      ...prev,
      take,
      page: 1,
    }));
  };

  const { error, isLoading, isFetching, refetch, isSuccess } = useQuery(
    [queryName, filters],
    () => {
      return request.get(url, {
        token,
        params: {
          ...filters,
        },
      });
    },
    {
      enabled: !!url,
      onSuccess: data => {
        setAllData(data);
      },
    },
  );

  // Calculate the paginated data based on the current page and items per page
  const paginatedData = useMemo(() => {
    const startIndex = (pagination.page - 1) * pagination.take;
    const endIndex = startIndex + pagination.take;
    return allData.slice(startIndex, endIndex);
  }, [allData, pagination.page, pagination.take]);

  // Calculate the total number of pages
  const totalPages = useMemo(() => {
    return Math.ceil(allData.length / pagination.take);
  }, [allData, pagination.take]);

  return {
    setPaginationParameters: setPagination,
    setFilters,
    setPage,
    setTake,
    setSort,
    filters,
    sort,
    data: paginatedData,
    allData,
    error,
    isFetching,
    isLoading,
    refetch,
    resetFilter,
    isSuccess,
    pagination,
    totalPages,
  };
}
