import palette from '../../../../theme/palette';

export default () => ({
  paper: {
    width: '100%',
    padding: '16px',
    margin: '6px',
  },

  divider: {
    margin: '1rem 0 1rem 0',
  },

  fullWidth: {
    width: '100%',
  },

  filterInput: {
    width: '100%',
    '& label': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '0.6rem',
      '@media (min-width: 1200px)': {
        fontSize: '1rem',
      },
    },
    '& .MuiSvgIcon-root': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  buttonClearFilter: {
    marginRight: '1rem',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  datePicker: {
    width: '100%',
    '& .MuiSvgIcon-root': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
});

export const ParceirosTableRowStyles = {
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableRowStyle: {
    '&:hover': {
      backgroundColor: '#F6FBFF',
    },
  },
  tableCellPaddding: {
    padding: '0',
  },
  disabledButton: {
    color: 'grey',
    cursor: 'not-allowed',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  tableRow: {
    backgroundColor: palette.background.default,
    width: '100%',
    display: 'table',
    height: '82px',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  tableCell: {
    fontSize: '14px',
    lineHeight: '20px',
    color: palette.text.secondary,
  },
  tableCellStatus: {
    display: 'flex',
    flexDirection: 'row',
  },
  tr: {
    height: '82px',
  },
  trRequisitos: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  cnpjCaption: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: palette.text.disabled,
  },
  orgLine: {
    marginTop: '4px',
  },
  accessTimeStyle: {
    padding: '2px',
    minWidth: '2rem',
  },
  disabledAccessTimeStyle: {
    padding: '2px',
    minWidth: '2rem',
    color: 'grey',
    cursor: 'not-allowed',
  },
  checkCircleStyle: {
    padding: '2px',
    minWidth: '2rem',
    color: '#21AC2A',
  },
  disabledLabelStyle: {
    color: 'grey',
  },
  badgeContratado: { color: palette.common.white, backgroundColor: palette.secondary.main },
  badgeCancelado: { color: palette.common.white, backgroundColor: palette.common.black },
};
