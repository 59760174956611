import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Typography, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { formatCNPJ, formatCPF, isValidCNPJ } from '@brazilian-utils/brazilian-utils';

function EnhancedTableRow({
  data: {
    energyUtilityAcronym,
    consumerUnitHolderIdentificationNumber,
    consumerUnitCode,
    statusReason,
    updatedAt,
    status,
    publicId,
    externalId,
    lastReadingDate,
    monitorLastRefresh,
    createdAt,
    labelStatus,
  },
}) {
  const intl = useIntl();

  return (
    <>
      <TableRow key={publicId}>
        <TableCell>
          <Typography variant="subtitle1">{consumerUnitCode}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{energyUtilityAcronym}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">
            {isValidCNPJ(consumerUnitHolderIdentificationNumber)
              ? formatCNPJ(consumerUnitHolderIdentificationNumber)
              : formatCPF(consumerUnitHolderIdentificationNumber)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{intl.formatDate(createdAt)}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{intl.formatDate(updatedAt)}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{externalId}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{status}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{labelStatus}</Typography>
        </TableCell>
        <TableCell>
          {statusReason && (
            <Tooltip title={statusReason} arrow>
              <Typography variant="subtitle1">
                {statusReason.length > 100 ? `${statusReason.slice(0, 100)}...` : statusReason}
              </Typography>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{lastReadingDate && intl.formatDate(lastReadingDate)}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{monitorLastRefresh && intl.formatDate(monitorLastRefresh)}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

EnhancedTableRow.propTypes = {
  data: PropTypes.shape({
    energyUtilityAcronym: PropTypes.string,
    consumerUnitHolderIdentificationNumber: PropTypes.string,
    consumerUnitCode: PropTypes.string,
    statusReason: PropTypes.string,
    updatedAt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    publicId: PropTypes.string.isRequired,
    externalId: PropTypes.string,
    lastReadingDate: PropTypes.string,
    monitorLastRefresh: PropTypes.string,
  }).isRequired,
};

export default EnhancedTableRow;
