const INITIAL_FILTER_STATE = {
  contraparte: null,
  distribuidora: null,
  dataFechamento: null,
  parceiro: null,
};

const INITIAL_SORT_STATE = {
  cliente: null,
  dataFechamento: 'ASC',
};

export { INITIAL_FILTER_STATE, INITIAL_SORT_STATE };
