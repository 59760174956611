import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Portlet, PortletContent } from '../../../../../components';
import ClientesParametrosTablePagination from './ClientesParametrosTablePagination';
import { ClientesTableRow } from './ClientesTableRow';
import { ClientesTableRowStyles } from '../Clientes.style';
import TableColumnSortButton from '../../../../../theme/components/TableColumnSortButton/TableColumnSortButton';

const useStyles = makeStyles(ClientesTableRowStyles);

export default function ClientesParametrosTable(props) {
  const classes = useStyles(props);
  const {
    data: clientesComissionaveis,
    total,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    setSorts,
    sorts,
  } = props;
  const rootClassName = cls(classes.root);

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <Table width="100%" className={classes.mainTable}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.rowShadow}>
              <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                  Cliente
                  <TableColumnSortButton value={sorts?.cliente || ''} onChange={sort => setSorts({ cliente: sort })} />
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">Distribuidora</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">Valor comissão</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">Parcela 1</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                  Data Contrato
                  <TableColumnSortButton
                    value={sorts?.dataFechamento || ''}
                    onChange={sort => setSorts({ dataFechamento: sort })}
                  />
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">Parcela 2</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                  Data Pgto Fatura
                  <TableColumnSortButton
                    value={sorts?.dataFechamento || ''}
                    onChange={sort => setSorts({ dataPagamentoPrimeiraParcela: sort })}
                  />
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Typography variant="body2">Parceiro</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!clientesComissionaveis && (
              <TableRow width="100%" className={classes.tr}>
                <TableCell className={classes.tableCell} width="100%">
                  Ops. Não encontramos nada.
                </TableCell>
              </TableRow>
            )}
            {clientesComissionaveis.map(row => {
              return <ClientesTableRow key={row.id} clientesData={row} classes={classes} />;
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Registros por página"
                onPageChange={() => {}}
                ActionsComponent={() => (
                  <ClientesParametrosTablePagination
                    total={total}
                    onChangePage={handleChangePage}
                    page={currentPage}
                    rowsPerPage={rowsPerPage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </PortletContent>
    </Portlet>
  );
}

ClientesParametrosTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};
