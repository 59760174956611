import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { capitalize } from 'lodash';
import palette from '../../../theme/palette';

export const ContratoMigracaoAzPeriodo = props => {
  const { dataInicio, dataFim } = props;
  const inicioDate = DateTime.fromISO(dataInicio);
  const fimDate = DateTime.fromISO(dataFim).minus({ days: 1 });

  const formatData = data => capitalize(data.toFormat('LLL yy').replace('.', ''));

  let periodoDisponibilizado = <></>;

  if (inicioDate.month === fimDate.month && inicioDate.year === fimDate.year) {
    periodoDisponibilizado = <>{formatData(inicioDate)}</>;
  } else if (inicioDate.month === 1 && fimDate.month === 12 && inicioDate.year === fimDate.year) {
    periodoDisponibilizado = <>{inicioDate.toFormat('yyyy')}</>;
  } else {
    periodoDisponibilizado = (
      <>
        {formatData(inicioDate)}
        <span style={{ color: palette.primary.main }}>{' > '}</span>
        {formatData(fimDate)}
      </>
    );
  }
  return <span>{periodoDisponibilizado}</span>;
};

ContratoMigracaoAzPeriodo.propTypes = {
  dataInicio: PropTypes.string.isRequired,
  dataFim: PropTypes.string.isRequired,
};
