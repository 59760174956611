import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { usePagination } from '../../../../theme/hooks/usePagination';
import { useAuth } from '../../../../auth/authProvider';
import request from '../../../../services/network/request';

const NotificationFilterContext = createContext({
  notificationsWithTotalCount: [],
  notificationFilterOptions: [],
  isFetchingNotificationsWithTotalCount: false,
  isFeatchingNotificationFilterOptions: false,
  isFeatchingRetryNotification: false,
  retrySendNotification() {},
  error: null,
  setPaginationParameters() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setAlert() {},
  parametros: {
    filters: null,
    sort: {},
    page: 1,
    take: 0,
  },
});

export const NotificationFilterProvider = ({ children }) => {
  const { token } = useAuth();
  const [alert, setAlert] = useState({ show: false, message: null, severity: null });

  const {
    data: notificationsWithTotalCount,
    isFetching: isFetchingNotificationsWithTotalCount,
    error,
    parametros,
    setFilter,
    setFilters,
    resetFilter,
    setPage,
  } = usePagination({
    queryName: 'fetch-notifications',
    url: '/notification/customers',
    method: 'get',
    defaultFilters: { filter: { limit: 10, take: 0 } },
  });

  const { data: notificationFilterOptions, isFetching: isFeatchingNotificationFiltersOptions } = useQuery(
    ['notification-filters-options'],
    () => request.get('/notification/customers/filters', { token }),
  );

  const [retrySendNotification, { isLoading: isFeatchingRetryNotification }] = useMutation(
    async publicIds => {
      await request.post(`/notification/customers/retry`, { publicIds }, { token });
    },
    {
      onSuccess: () => {
        setAlert({
          show: true,
          message:
            'A retentativa foi concluída com sucesso. Aguarde alguns segundos e atualize a página para verificar se o status foi atualizado para "Concluído".',
          severity: 'success',
        });
      },
      onError: () => {
        setAlert({
          show: true,
          message:
            'Ocorreram erros durante a tentativa de reenviar as notificações. Atualize a página para verificar quais foram bem sucedidas (status "Concluído")',
          severity: 'error',
        });
      },
    },
  );

  return (
    <NotificationFilterContext.Provider
      value={{
        notificationsWithTotalCount,
        isFetchingNotificationsWithTotalCount,
        notificationFilterOptions,
        isFeatchingNotificationFiltersOptions,
        isFeatchingRetryNotification,
        retrySendNotification,
        error,
        parametros,
        setPage,
        alert,
        setAlert,
        setFilter,
        resetFilter,
        setFilters,
      }}
    >
      <>{children}</>
    </NotificationFilterContext.Provider>
  );
};

export const useNotificationFilter = () => useContext(NotificationFilterContext);

NotificationFilterProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
