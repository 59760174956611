import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { usePagination } from '../../../theme/hooks/usePagination';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';

const CredentialStatusTrackerContext = createContext({
  energyUtilityCredentialsWithTotalCount: [],
  energyUtilityCredentialsFilterOptions: [],
  isFetchingEnergyUtilityCredentialsFilterOptions: false,
  isFetchingEnergyUtilityCredentialsWithTotalCount: false,
  errorFetchEnergyUtilityCredentialsPaginated: null,
  errorEnergyUtilityCredentialsFilterOptions: null,
  setPaginationParameters() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setAlert() {},
  alert: { show: false, message: null, severity: null },
  parametros: {
    filters: null,
    sort: {},
    page: 1,
    take: 0,
  },
});

export const CredentialStatusTrackerProvider = ({ children }) => {
  const { token } = useAuth();
  const {
    data: energyUtilityCredentialsWithTotalCount,
    isFetching: isFetchingEnergyUtilityCredentialsWithTotalCount,
    error: errorFetchEnergyUtilityCredentialsPaginated,
    parametros,
    setFilter,
    setFilters,
    resetFilter,
  } = usePagination({
    queryName: 'fetch-energy-utility-credentials-paginated',
    url: '/energy-utility-credential/paginated',
    method: 'get',
    defaultFilters: { filter: { take: 0, limit: 10, offset: 1 } },
  });

  const {
    error: errorEnergyUtilityCredentialsFilterOptions,
    data: energyUtilityCredentialsFilterOptions,
    isFetching: isFetchingEnergyUtilityCredentialsFilterOptions,
  } = useQuery(['energy-utility-credentials-filter-options'], () =>
    request.get('/energy-utility-credential/filters', { token }),
  );

  return (
    <CredentialStatusTrackerContext.Provider
      value={{
        energyUtilityCredentialsWithTotalCount,
        isFetchingEnergyUtilityCredentialsWithTotalCount,
        energyUtilityCredentialsFilterOptions,
        isFetchingEnergyUtilityCredentialsFilterOptions,
        errorEnergyUtilityCredentialsFilterOptions,
        errorFetchEnergyUtilityCredentialsPaginated,
        parametros,
        setFilter,
        resetFilter,
        setFilters,
      }}
    >
      <>{children}</>
    </CredentialStatusTrackerContext.Provider>
  );
};

export const useCredentialStatusTracker = () => useContext(CredentialStatusTrackerContext);

CredentialStatusTrackerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
