import React from 'react';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  capitalize,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useNotificationFilter } from '../NotificationFilterProvider';
import { usePermissions } from '../../../../../hooks/usePermissions/usePermissions';

export function FilterForm({ filterOptions, handleRetry, selected, handleUpdate }) {
  const { getPermissions } = usePermissions();
  const editar = getPermissions();
  const { setFilters, parametros, resetFilter } = useNotificationFilter();
  const {
    telephone,
    email,
    templateName,
    channel,
    status,
    createdAtStart,
    createdAtEnd,
    consumerUnitCode,
    updatedAtStart,
    updatedAtEnd,
  } = parametros?.filter || {};
  const { statusOptions, channelOptions, templateNameOptions } = filterOptions || {};
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      status: status || '',
      templateName: templateName || '',
      channel: channel || '',
      consumerUnitCode: consumerUnitCode || '',
      telephone: telephone || '',
      email: email || '',
      updatedAtStart: updatedAtStart || null,
      updatedAtEnd: updatedAtEnd || null,
      createdAtStart: createdAtStart || null,
      createdAtEnd: createdAtEnd || null,
    },
  });

  const handleReset = () => {
    reset();
    resetFilter();
  };

  const onSubmit = data => {
    const filters = {
      status: data.status === 'all' ? '' : data.status,
      templateName: data.templateName === 'all' ? '' : data.templateName,
      channel: data.channel === 'all' ? '' : data.channel,
      consumerUnitCode: data.consumerUnitCode === 'all' ? '' : data.consumerUnitCode,
      telephone: data.telephone === 'all' ? '' : data.telephone,
      email: data.email === 'all' ? '' : data.email,
      updatedAtStart: data.updatedAtStart,
      updatedAtEnd: data.updatedAtEnd,
      createdAtStart: data.createdAtStart,
      createdAtEnd: data.createdAtEnd,
    };

    setFilters(filters);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Filtros
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="Status">
                    <MenuItem value="all">Selecionar Todos</MenuItem>
                    {statusOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {capitalize(option.label)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Nome do Template</InputLabel>
              <Controller
                name="templateName"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="Nome do Template">
                    <MenuItem value="all">Selecionar Todos</MenuItem>
                    {templateNameOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Canal</InputLabel>
              <Controller
                name="channel"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="Canal">
                    <MenuItem value="all">Selecionar Todos</MenuItem>
                    {channelOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {capitalize(option.label)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <Controller
                name="consumerUnitCode"
                control={control}
                render={({ field }) => <TextField {...field} label="Unidade Consumidora" fullWidth />}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <Controller
                name="telephone"
                control={control}
                render={({ field }) => <TextField {...field} label="Telefone" fullWidth />}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <Controller
                name="email"
                control={control}
                render={({ field }) => <TextField {...field} label="Email" fullWidth />}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="updatedAtStart"
              control={control}
              render={({ field: { value } }) => (
                <DatePicker
                  style={{ width: '100%' }}
                  value={value}
                  format="dd/MM/yyyy"
                  label="Data Inicial de Atualização"
                  onChange={date => setValue('updatedAtStart', date.startOf('day').toISO())}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="updatedAtEnd"
              control={control}
              render={({ field: { value } }) => (
                <DatePicker
                  style={{ width: '100%' }}
                  value={value}
                  format="dd/MM/yyyy"
                  label="Data Final de Atualização"
                  onChange={date => setValue('updatedAtEnd', date.endOf('day').toISO())}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="createdAtStart"
              control={control}
              render={({ field: { value } }) => (
                <DatePicker
                  style={{ width: '100%' }}
                  value={value}
                  format="dd/MM/yyyy"
                  label="Data Inicial de Criação"
                  onChange={date => setValue('createdAtStart', date.endOf('day').toISO())}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="createdAtEnd"
              control={control}
              render={({ field: { value } }) => (
                <DatePicker
                  style={{ width: '100%' }}
                  value={value}
                  format="dd/MM/yyyy"
                  label="Data Final de Criação"
                  onChange={date => setValue('createdAtEnd', date.endOf('day').toISO())}
                />
              )}
            />
          </Grid>
          <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: 10 }}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleUpdate}>
                ATUALIZAR
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                APLICAR FILTROS
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={handleReset}>
                LIMPAR FILTROS
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => handleRetry(selected)} disabled={!editar || !selected.length}>
                Reenviar Notificações
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

FilterForm.propTypes = {
  filterOptions: PropTypes.shape({
    statusOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    channelOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    templateNameOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,

  handleRetry: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FilterForm;
