import React from 'react';
import { Typography, Card, CardContent, makeStyles } from '@material-ui/core';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MoneyIcon from '@material-ui/icons/Money';
import WarningIcon from '@material-ui/icons/Warning';
import { useHistory } from 'react-router-dom';
import { PeopleOutline, VpnKey, Build, NotificationsNone } from '@material-ui/icons';
import { Dashboard as DashboardLayout } from '../../layouts';
import styles from './Dashboard.style';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import { GruposBackstage } from '../../helpers/enums';
import Icon from '../../components/Icon/Icon';

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const classes = useStyles(props);

  const { permissoesModulo } = usePermissions();

  const { push } = useHistory();

  const cardsValue = [
    {
      pushUrl: () => push('/base-usuarios'),
      label: 'Cadastros',
      icon: <SupervisedUserCircleIcon className={classes.cardIcon} />,
    },
    {
      pushUrl: () => push('/negocios'),
      label: GruposBackstage.NEGOCIOS,
      icon: <FileCopyIcon className={classes.cardIcon} />,
    },
    {
      pushUrl: () => push('/validacoes'),
      label: 'Validações',
      icon: <CheckCircleIcon className={classes.cardIcon} />,
    },
    {
      pushUrl: () => push('/parametros'),
      label: GruposBackstage.PARAMETROS,
      icon: <SettingsApplicationsIcon className={classes.cardIcon} />,
    },
    {
      pushUrl: () => push('/preco'),
      label: GruposBackstage.PRECOS,
      icon: <MoneyIcon className={classes.cardIcon} />,
    },
    {
      pushUrl: () => push('/contratacao'),
      label: GruposBackstage.CONTRATACAO,
      icon: <LocalAtmIcon className={classes.cardIcon} />,
    },
    {
      pushUrl: () => push('/credito/analises'),
      label: 'Crédito',
      icon: (
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <AttachMoneyIcon className={classes.cardIcon} />
          <WarningIcon
            className={classes.cardIcon}
            style={{
              position: 'relative',
              right: '18px',
              width: '18px',
              height: '18px',
            }}
          />
        </div>
      ),
    },
    {
      pushUrl: () => push('/GD/validacao'),
      label: 'GD',
      icon: <Icon size={38} name="ComputerSetting" />,
    },
    {
      pushUrl: () => push('/comunidade'),
      label: 'Comunidade',
      icon: <PeopleOutline className={classes.cardIcon} />,
    },
    {
      pushUrl: () => push('/gestao-credenciais'),
      label: GruposBackstage.GESTAO_CREDENCIAIS,
      icon: <VpnKey className={classes.cardIcon} />,
    },
    {
      pushUrl: () => push('/integracoes'),
      label: GruposBackstage.INTEGRACOES,
      icon: <Build className={classes.cardIcon} />,
    },
    {
      pushUrl: () => push('/comunicacoes'),
      label: GruposBackstage.COMUNICACOES,
      icon: <NotificationsNone className={classes.cardIcon} />,
    },
  ];

  return (
    <>
      <DashboardLayout title="Dashboard">
        <div className={classes.root}>
          {cardsValue.map(
            ({ label, icon, pushUrl }) =>
              (permissoesModulo[label] || permissoesModulo[label] === undefined) && (
                <Card key={label} className={classes.card} onClick={() => pushUrl()} styles={{ position: 'relative' }}>
                  <CardContent className={classes.cardContent}>
                    {icon}
                    <Typography className={classes.cardText} variant="h5">
                      {label}
                    </Typography>
                  </CardContent>
                </Card>
              ),
          )}
        </div>
      </DashboardLayout>
    </>
  );
}

export default Dashboard;
