import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox, Typography, IconButton, Tooltip } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { ExpandableTableRow } from './ExpandableTableRow';

const dateConfig = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

function EnhancedTableRow({
  data: {
    process,
    templateName,
    consumerUnitCode,
    channel,
    attempts,
    updatedAt,
    message,
    status,
    publicId,
    metadata,
    identifiers,
    createdAt,
  },
  isItemSelected,
  handleClick,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow key={publicId}>
        <TableCell>
          <Checkbox
            onClick={() => handleClick(publicId)}
            color="primary"
            checked={isItemSelected}
            disabled={status !== 'Erro'}
            inputProps={{
              'aria-labelledby': publicId,
            }}
          />
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{process}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{templateName}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{consumerUnitCode}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{channel}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{attempts}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{DateTime.fromISO(createdAt).toLocaleString(dateConfig)}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{DateTime.fromISO(updatedAt).toLocaleString(dateConfig)}</Typography>
        </TableCell>
        <TableCell>
          {message && (
            <Tooltip title={message} arrow>
              <Typography variant="subtitle1">
                {message.length > 100 ? `${message.slice(0, 100)}...` : message}
              </Typography>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{status}</Typography>
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow style={{ display: open ? 'contents' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        <TableCell colSpan={12} style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          <div style={{ marginBottom: 10 }}>
            <ExpandableTableRow
              title="Identificadores"
              description="Parâmetros utilizados para identificar informações gerais sobre o processo"
              data={identifiers}
              open={open}
            />
          </div>

          <ExpandableTableRow
            title="Metadados"
            description="Dados para utilizar no template"
            data={metadata}
            open={open}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

EnhancedTableRow.propTypes = {
  data: PropTypes.shape({
    process: PropTypes.string.isRequired,
    templateName: PropTypes.string,
    consumerUnitCode: PropTypes.string,
    channel: PropTypes.string.isRequired,
    attempts: PropTypes.number.isRequired,
    updatedAt: PropTypes.string.isRequired,
    message: PropTypes.string,
    status: PropTypes.string.isRequired,
    publicId: PropTypes.string.isRequired,
    metadata: PropTypes.object,
    identifiers: PropTypes.object,
  }).isRequired,

  isItemSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default EnhancedTableRow;
