import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorsParametrosHeader } from './ErrorsParametrosHeader';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import { ErrorsParametrosFilters } from './ErrorsParametrosFilters';

export const ErrorsParametros = props => {
  const { handleDownloadInvoices, filters, setFilters, setSelectedFilter } = props;

  return (
    <Box mb={2}>
      <TableHeader>
        <ErrorsParametrosHeader
          handleDownloadInvoices={handleDownloadInvoices}
          filters={filters}
          setFilters={setFilters}
        />
        <ErrorsParametrosFilters filters={filters} setFilters={setFilters} setSelectedFilter={setSelectedFilter} />
      </TableHeader>
    </Box>
  );
};

ErrorsParametros.propTypes = {
  handleDownloadInvoices: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    partner: PropTypes.string,
    status: PropTypes.string,
    referenceDate: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
};
