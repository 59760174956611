import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableCell, Checkbox, TableRow } from '@material-ui/core';

const headCells = [
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'templateName',
    label: 'Template',
  },
  {
    id: 'channel',
    label: 'Canal',
  },
  {
    id: 'consumerUnitCode',
    label: 'Unidade Consumidora',
  },
  {
    id: 'telephone',
    label: 'Telefone',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'comunicationDate',
    label: 'Data de Envio',
  },
  {
    id: 'createdAt',
    label: 'Data de criação',
  },
  {
    id: 'lastUpdateDate',
    label: 'Data da última atualização',
  },
  {
    id: 'emptyHeader',
    label: '',
  },
];

export function NotificationFilterTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'selecione todos os processos de notificação',
            }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

NotificationFilterTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
