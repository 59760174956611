import React from 'react';
import { Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useCredentialStatusTracker } from '../CredentialStatusTrackerProvider';

export function FilterForm({ filterOptions }) {
  const { setFilters, parametros, resetFilter } = useCredentialStatusTracker();
  const {
    status,
    updatedAtStart,
    updatedAtEnd,
    consumerUnitCode,
    externalId,
    energyUtilityAcronym,
    createdAtStart,
    createdAtEnd,
  } = parametros?.filter || {};
  const { statuses, energyUtilities } = filterOptions || {};
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      status: status || '',
      updatedAtStart: updatedAtStart || null,
      updatedAtEnd: updatedAtEnd || null,
      createdAtStart: createdAtStart || null,
      createdAtEnd: createdAtEnd || null,
      consumerUnitCode: consumerUnitCode || '',
      externalId: externalId || '',
      energyUtilityAcronym: energyUtilityAcronym || '',
    },
  });

  const handleReset = () => {
    reset();
    resetFilter();
  };

  const onSubmit = data => {
    const filters = {
      energyUtilityAcronym: data.energyUtilityAcronym === 'all' ? '' : data.energyUtilityAcronym,
      updatedAtStart: data.updatedAtStart,
      updatedAtEnd: data.updatedAtEnd,
      createdAtStart: data.createdAtStart,
      createdAtEnd: data.createdAtEnd,
      consumerUnitCode: data.consumerUnitCode,
      externalId: data.externalId,
      status: data.status,
      offset: 1,
    };

    setFilters(filters);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Filtros
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="Status">
                    <MenuItem value="all">Selecionar Todos</MenuItem>
                    {statuses.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="consumerUnitCode"
              control={control}
              render={({ field: { value, ref, onChange } }) => (
                <TextField
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  label="Código Unidade Consumidora"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="externalId"
              control={control}
              render={({ field: { value, ref, onChange } }) => (
                <TextField onChange={onChange} inputRef={ref} value={value} label="ID Externo" fullWidth />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Distribuidora</InputLabel>
              <Controller
                name="energyUtilityAcronym"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="Distribuidora">
                    <MenuItem value="all">Selecionar Todos</MenuItem>
                    {energyUtilities.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="updatedAtStart"
              control={control}
              render={({ field: { value } }) => (
                <DatePicker
                  style={{ width: '100%' }}
                  value={value}
                  format="dd/MM/yyyy"
                  label="Data Inicial de Atualização"
                  onChange={date => setValue('updatedAtStart', date.startOf('day').toISO())}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="updatedAtEnd"
              control={control}
              render={({ field: { value } }) => (
                <DatePicker
                  style={{ width: '100%' }}
                  value={value}
                  format="dd/MM/yyyy"
                  label="Data Final de Atualização"
                  onChange={date => setValue('updatedAtEnd', date.endOf('day').toISO())}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="createdAtStart"
              control={control}
              render={({ field: { value } }) => (
                <DatePicker
                  style={{ width: '100%' }}
                  value={value}
                  format="dd/MM/yyyy"
                  label="Data Inicial de Criação"
                  onChange={date => setValue('createdAtStart', date.startOf('day').toISO())}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="createdAtEnd"
              control={control}
              render={({ field: { value } }) => (
                <DatePicker
                  style={{ width: '100%' }}
                  value={value}
                  format="dd/MM/yyyy"
                  label="Data Final de Criação"
                  onChange={date => setValue('createdAtEnd', date.endOf('day').toISO())}
                />
              )}
            />
          </Grid>

          <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: 10 }}>
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                APLICAR FILTROS
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={handleReset}>
                LIMPAR FILTROS
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

FilterForm.propTypes = {
  filterOptions: PropTypes.shape({
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default FilterForm;
