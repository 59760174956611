import React from 'react';

export default () => (
  <>
    <path
      d="M12.9214 35.501H26.8165M19.8689 28.5534V35.501M24.2112 4.23689H29.9424C31.8879 4.23689 32.8606 4.23689 33.6036 4.61552C34.2574 4.94855 34.7887 5.47997 35.1218 6.13362C35.5005 6.87669 35.5005 7.84944 35.5005 9.79495V22.9953C35.5005 24.9408 35.5005 25.9137 35.1218 26.6567C34.7887 27.3103 34.2574 27.8418 33.6036 28.1748C32.8606 28.5534 31.8879 28.5534 29.9424 28.5534H9.44757C7.829 28.5534 7.01971 28.5534 6.38131 28.289C5.53015 27.9365 4.85389 27.2601 4.50132 26.409C4.23689 25.7706 4.23689 24.9613 4.23689 23.3427M11.1845 2.5V3.22371M11.1845 19.1455V19.8693M19.8684 11.1845H19.1446M3.22371 11.1845H2.5M17.3242 5.04373L16.8123 5.55547M5.55601 16.8134L5.04427 17.3251M5.04479 5.04415L5.55653 5.55589M16.8146 16.8137L17.3263 17.3254M14.6583 11.1845C14.6583 13.103 13.103 14.6583 11.1845 14.6583C9.26595 14.6583 7.71068 13.103 7.71068 11.1845C7.71068 9.26595 9.26595 7.71068 11.1845 7.71068C13.103 7.71068 14.6583 9.26595 14.6583 11.1845Z"
      stroke="black"
      strokeWidth="4.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
