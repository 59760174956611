import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { usePagination } from '../../../theme/hooks/usePagination';
import { useAuth } from '../../../auth/authProvider';
import request from '../../../services/network/request';

const DistributedQueueTrackerContext = createContext({
  trackingNotificationsWithTotalCount: [],
  trackingNotificationFilterOptions: [],
  isFetchingTrackingNotificationsWithTotalCount: false,
  isFeatchingTrackingNotificationFilterOptions: false,
  retryTrackingNotification() {},
  error: null,
  setPaginationParameters() {},
  setFilter() {},
  resetFilter() {},
  setPage() {},
  setAlert() {},
  alert: { show: false, message: null, severity: null },
  parametros: {
    filters: null,
    sort: {},
    page: 1,
    take: 0,
  },
});

export const DistributedQueueTrackerProvider = ({ children }) => {
  const { token } = useAuth();
  const [alert, setAlert] = useState({ show: false, message: null, severity: null });

  const {
    data: trackingNotificationsWithTotalCount,
    isFetching: isFetchingTrackingNotificationsWithTotalCount,
    error,
    parametros,
    setFilter,
    setFilters,
    resetFilter,
    setPage,
  } = usePagination({
    queryName: 'fetch-tracking-notification',
    url: '/distribuited-generation/tracking/tracking-notification',
    method: 'get',
    defaultFilters: { filter: { take: 0, limit: 10 } },
  });

  const {
    data: trackingNotificationFilterOptions,
    isFetching: isFeatchingTrackingNotificationFilterOptions,
  } = useQuery(['tracking-notification-filter-options'], () =>
    request.get('/distribuited-generation/tracking/tracking-notification/filters', { token }),
  );

  const [retryTrackingNotification] = useMutation(
    async publicIds => {
      await request.post(
        `/distribuited-generation/tracking/tracking-notification/retry?publicIds=${publicIds.join(',')}`,
        {},
        { token },
      );
    },
    {
      onSuccess: () => {
        setAlert({
          show: true,
          message:
            'A retentativa foi concluída com sucesso. Aguarde alguns segundos e atualize a página para verificar se o status foi atualizado para "Concluído".',
          severity: 'success',
        });
      },

      onError: err => {
        setAlert({
          show: true,
          message: err?.message || 'Ocorreram erros durante a nova tentativa.',
          severity: 'error',
        });
      },
    },
  );

  return (
    <DistributedQueueTrackerContext.Provider
      value={{
        trackingNotificationsWithTotalCount,
        isFetchingTrackingNotificationsWithTotalCount,
        trackingNotificationFilterOptions,
        isFeatchingTrackingNotificationFilterOptions,
        retryTrackingNotification,
        error,
        parametros,
        setPage,
        setFilter,
        resetFilter,
        setFilters,
        alert,
        setAlert,
      }}
    >
      <>{children}</>
    </DistributedQueueTrackerContext.Provider>
  );
};

export const useDistributedQueueTracker = () => useContext(DistributedQueueTrackerContext);

DistributedQueueTrackerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
