import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import styles from './styles';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import { GruposBackstage } from '../../../../helpers/enums';

const useStyles = makeStyles(styles);

const Sidebar = props => {
  const classes = useStyles(props);
  const { className } = props;

  const { permissoesModulo } = usePermissions();

  const rootClassName = classNames(classes.root, className);

  const cardsValue = [
    {
      pushUrl: '/base-usuarios',
      label: 'Cadastros',
    },
    {
      pushUrl: '/preco',
      label: GruposBackstage.PRECOS,
    },
    {
      pushUrl: '/contratacao',
      label: GruposBackstage.CONTRATACAO,
    },
    {
      pushUrl: '/negocios',
      label: GruposBackstage.NEGOCIOS,
    },
    {
      pushUrl: '/validacoes',
      label: 'Validações',
    },
    {
      pushUrl: '/parametros',
      label: GruposBackstage.PARAMETROS,
    },
    {
      pushUrl: '/credito/analises',
      label: 'Crédito',
    },
    {
      pushUrl: '/GD/validacao',
      label: GruposBackstage.GD,
    },
    {
      pushUrl: '/comunidade',
      label: GruposBackstage.COMUNIDADE,
    },
    {
      pushUrl: '/gestao-credenciais',
      label: GruposBackstage.GESTAO_CREDENCIAIS,
    },
    {
      pushUrl: '/integracoes',
      label: GruposBackstage.INTEGRACOES,
    },
    {
      pushUrl: '/comunicacoes',
      label: GruposBackstage.COMUNICACOES,
    },
  ];

  return (
    <nav className={rootClassName}>
      <div className={classes.headerWrapper}>
        <Typography variant="h6">Menu</Typography>
      </div>
      <List component="div" disablePadding>
        <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={NavLink} to="/">
          <ListItemText classes={{ primary: classes.listItemText }} primary="Início" />
        </ListItem>

        {cardsValue.map(
          ({ pushUrl, label }) =>
            (permissoesModulo[label] || permissoesModulo[label] === undefined) && (
              <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                component={NavLink}
                to={pushUrl}
              >
                <ListItemText classes={{ primary: classes.listItemText }} primary={label} />
              </ListItem>
            ),
        )}
      </List>
    </nav>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
};

Sidebar.defaultProps = {
  className: '',
};

export default Sidebar;
