import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  TextField,
  MenuItem,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import { req } from '@omega-energia/react';
import { PartnerInvoiceStatus } from '@omega-energia/utilities';
// import { useMutation } from 'react-query';
import { DateTime } from 'luxon';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../../auth/authProvider';

function ModalEditarDados({ isOpen, onClose, id, invoiceData, refetch }) {
  const { token } = useAuth();

  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      amount: invoiceData?.amount && MoneyMask(invoiceData?.amount),
      invoiceNumber: invoiceData?.invoiceNumber,
      status: invoiceData?.status,
      issueDate: invoiceData?.issueDate,
      referenceDate: invoiceData?.referenceDate,
      paymentDate: invoiceData?.paymentDate,
      uploadDate: invoiceData?.uploadDate,
    },
  });

  const onSubmit = async data => {
    try {
      data.amount = UnMaskMoney(data.amount);
      setIsLoading(true);
      await req.patch(`/backstage/comunidade/invoices/${id}`, data, { token });
      setSnackbar({ open: true, message: 'Parceiro atualizado com sucesso!' });
      setTimeout(() => {
        onClose();
        refetch();
      }, 2000);
    } catch (error) {
      setSnackbar({ open: true, message: error?.response?.data?.message || 'Houve um erro ao atualizar o parceiro' });
    } finally {
      setIsLoading(false);
    }
  };

  function UnMaskMoney(value) {
    if (typeof value === 'number') {
      return value.toFixed(2); // Se já for um número, apenas formata como string decimal
    }

    const numericValue = value.replace(/\D/g, ''); // Remove tudo que não for número
    return numericValue ? (parseInt(numericValue, 10) / 100).toFixed(2) : '';
  }

  function MoneyMask(value) {
    if (typeof value === 'number') {
      return value?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }

    const numericValue = value.replace(/\D/g, ''); // Remove tudo que não for número
    if (!numericValue) {
      return '';
    }

    const formatted = (parseInt(numericValue, 10) / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatted;
  }

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>
          <Typography>Edição parceiro</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" mb={2}>
              <TextField
                disabled
                variant="outlined"
                label="Valor Comissão"
                name="amount"
                placeholder="R$1.000,00"
                {...register('amount', {
                  required: 'Este campo é obrigatório',
                })}
                onChange={event => {
                  setValue('amount', MoneyMask(event.target.value));
                }}
                error={!!errors.amount}
                helperText={errors.amount?.message?.toString()}
              />
            </Box>
            <Box display="flex" flexDirection="row" mb={2} style={{ gap: '12px' }}>
              <TextField
                variant="outlined"
                label="Número da NF"
                name="invoiceNumber"
                placeholder="0001"
                style={{ flex: 1 }}
                {...register('invoiceNumber')}
                error={!!errors.invoiceNumber}
                helperText={errors.invoiceNumber?.message?.toString()}
              />
              <TextField
                select
                variant="outlined"
                label="Status Pagamento"
                name="status"
                placeholder="Selecione o Status"
                style={{ flex: 1 }}
                value={getValues('status')}
                {...register('status', {
                  required: 'Este campo é obrigatório',
                })}
                error={!!errors.status}
                helperText={errors.status?.message?.toString()}
              >
                {Object.values(PartnerInvoiceStatus).map(status => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box display="flex" flexDirection="row" mb={2} style={{ gap: '12px' }}>
              <KeyboardDatePicker
                disableToolbar
                label="Data da Emissão"
                InputLabelProps={{
                  style: { color: 'rgba(0, 0, 0, 0.6)' },
                }}
                format="dd/MM/yyyy"
                maxDate={DateTime.local().endOf('year')}
                {...register('issueDate')}
                value={getValues('issueDate') ? DateTime.fromISO(getValues('issueDate')).toJSDate() : null}
                onChange={date => {
                  if (date) {
                    const formattedDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd');
                    setValue('issueDate', formattedDate, { shouldValidate: true, shouldDirty: true });
                  } else {
                    setValue('issueDate', '', { shouldValidate: true, shouldDirty: true });
                  }
                }}
                error={!!errors.issueDate}
                helperText={errors.issueDate?.message?.toString()}
              />
              <KeyboardDatePicker
                disableToolbar
                disabled
                label="Mês Competência"
                InputLabelProps={{
                  style: { color: 'rgba(0, 0, 0, 0.6)' },
                }}
                format="dd/MM/yyyy"
                maxDate={DateTime.local().endOf('year')}
                {...register('referenceDate', {
                  required: 'Este campo é obrigatório',
                })}
                value={getValues('referenceDate') ? DateTime.fromISO(getValues('referenceDate')).toJSDate() : null}
                onChange={date => {
                  if (date) {
                    const formattedDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd');
                    setValue('referenceDate', formattedDate, { shouldValidate: true, shouldDirty: true });
                  } else {
                    setValue('referenceDate', '', { shouldValidate: true, shouldDirty: true });
                  }
                }}
                error={!!errors.referenceDate}
                helperText={errors.referenceDate?.message?.toString()}
              />
            </Box>
            <Box display="flex" flexDirection="row" mb={2} style={{ gap: '12px' }}>
              <KeyboardDatePicker
                disableToolbar
                label="Data pagamento"
                InputLabelProps={{
                  style: { color: 'rgba(0, 0, 0, 0.6)' },
                }}
                format="dd/MM/yyyy"
                maxDate={DateTime.local().endOf('year')}
                {...register('paymentDate')}
                value={getValues('paymentDate') ? DateTime.fromISO(getValues('paymentDate')).toJSDate() : null}
                onChange={date => {
                  if (date) {
                    const formattedDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd');
                    setValue('paymentDate', formattedDate, { shouldValidate: true, shouldDirty: true });
                  } else {
                    setValue('paymentDate', '', { shouldValidate: true, shouldDirty: true });
                  }
                }}
                error={!!errors.paymentDate}
                helperText={errors.paymentDate?.message?.toString()}
              />
              <KeyboardDatePicker
                disableToolbar
                label="Entrada no sistema"
                InputLabelProps={{
                  style: { color: 'rgba(0, 0, 0, 0.6)' },
                }}
                format="dd/MM/yyyy"
                maxDate={DateTime.local().endOf('year')}
                {...register('uploadDate')}
                value={getValues('uploadDate') ? DateTime.fromISO(getValues('uploadDate')).toJSDate() : null}
                onChange={date => {
                  if (date) {
                    const formattedDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd');
                    setValue('uploadDate', formattedDate, { shouldValidate: true, shouldDirty: true });
                  } else {
                    setValue('uploadDate', '', { shouldValidate: true, shouldDirty: true });
                  }
                }}
                error={!!errors.uploadDate}
                helperText={errors.uploadDate?.message?.toString()}
              />
            </Box>
            <Box display="flex" flexDirection="row" mb={2}>
              <TextField
                variant="outlined"
                label="Observação"
                {...register('comment')}
                name="comment"
                placeholder="Observação"
                style={{ flex: 1 }}
              />
            </Box>
            <DialogActions>
              <Button type="button" onClick={() => onClose()} color="primary" disabled={isLoading}>
                Cancelar
              </Button>
              <Button type="submit" color="primary" disabled={isLoading} style={{ minWidth: '75px' }}>
                {isLoading ? <CircularProgress size="14px" /> : 'Salvar'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        message={snackbar.message}
        onClose={() => setSnackbar({ open: false, message: '' })}
      />
    </>
  );
}

ModalEditarDados.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default ModalEditarDados;
