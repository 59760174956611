import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import styles from '../Clientes.style';
import TableHeader from '../../../../../theme/components/TableHeader/TableHeader';
import { INITIAL_FILTER_STATE } from '../ClientesTableConstants';
import CnpjOuNomeInput from '../../../../BaseUsuarios/components/CnpjOuNomeInput/CnpjOuNomeInput';

const useStyles = makeStyles(styles);

export const ClientesParametrosFilters = props => {
  const { filters, setFilters, hasFastFilterSelected, setSelectedFilter } = props;
  const classes = useStyles(props);

  const handleChangeFilter = (filter, value) => {
    const newFilters = hasFastFilterSelected ? { ...INITIAL_FILTER_STATE } : { ...filters };

    newFilters[filter] = value;
    setFilters({ ...newFilters });
    setSelectedFilter(undefined);
  };

  const handleFiltroDataFechamento = (newDate, filter) => {
    const dateParsed = newDate ? new DateTime(newDate).startOf('day').toISO() : null;
    handleChangeFilter(filter, dateParsed);
  };

  return (
    <TableHeader.Filters>
      <TableHeader.Input>
        <CnpjOuNomeInput
          className={classes.filterInput}
          id="filterContraparte"
          label="Cliente"
          value={filters.contraparte || ''}
          onChange={e => handleChangeFilter('contraparte', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className={classes.searchIcon} fontSize="large" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.6)' },
          }}
        />
      </TableHeader.Input>
      <TableHeader.Input>
        <TextField
          className={classes.filterInput}
          id="filterDistribuidora"
          label="Distribuidora"
          value={filters.distribuidora || ''}
          onChange={e => handleChangeFilter('distribuidora', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className={classes.searchIcon} fontSize="large" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.6)' },
          }}
        />
      </TableHeader.Input>
      <TableHeader.Input>
        <KeyboardDatePicker
          disableToolbar
          className={classes.datePicker}
          id="datePickerDataFechamento"
          label="Data de fechamento"
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.6)' },
          }}
          value={filters.dataFechamento}
          onChange={newDate => handleFiltroDataFechamento(newDate, 'dataFechamento')}
          format="dd/MM/yyyy"
          initialFocusedDate={DateTime.local().startOf('day')}
          maxDate={DateTime.local().endOf('year')}
        />
      </TableHeader.Input>
      <TableHeader.Input>
        <TextField
          className={classes.filterInput}
          id="filterParceiro"
          label="Parceiro"
          value={filters.parceiro || ''}
          onChange={e => handleChangeFilter('parceiro', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className={classes.searchIcon} fontSize="large" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.6)' },
          }}
        />
      </TableHeader.Input>
    </TableHeader.Filters>
  );
};

ClientesParametrosFilters.propTypes = {
  filters: PropTypes.shape({
    contraparte: PropTypes.string,
    statusContratacao: PropTypes.string,
    distribuidora: PropTypes.string,
    inicioContratacao: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
};
