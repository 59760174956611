import React, { useState, useEffect } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  CircularProgress,
  Snackbar,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from '@omega-energia/react';
import { useMutation, useQuery } from 'react-query';
import { Calendar, HistoricoAlteracaoAcao, HistoricoAlteracaoUsuarioTipo, isNil } from '@omega-energia/utilities';
import { DateTime } from 'luxon';
import { KeyboardDatePicker } from '@material-ui/pickers';
import req from '../../../../../services/network/request';
import palette from '../../../../../theme/palette';
import { useAuth } from '../../../../../auth/authProvider';
import { CancelarContratoDialog } from './CancelarContratoDialog/CancelarContratoDialog';

function renderReadonlyText(caption, text) {
  return (
    <>
      <Typography style={{ color: 'rgba(0, 0, 0, 0.38)' }} variant="overline">
        {caption}
      </Typography>
      <Typography variant="body1">{text}</Typography>
    </>
  );
}
function HistoricoDialog(props) {
  const { show, onClose, data } = props;
  const { token } = useAuth();

  const [showDialog, setShowDialog] = useState(false);

  const useStylesDialog = makeStyles(() => ({
    paper: { maxWidth: '800px', maxHeight: '500px' },
    borderHighlight: {
      border: `1px solid ${palette.border}`,
      borderRadius: '3px',
      borderBottom: `3px solid ${palette.primary.serena}`,
    },
    tableHead: {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed',
    },
    tableBody: {
      border: `1px solid ${palette.border}`,
      borderRadius: '3px',
      borderCollapse: 'unset',
      overflow: 'auto',
      display: 'block',
      height: '270px',
    },
    tableRow: {
      width: '100%',
      display: 'table',
    },
    grey: {
      color: palette.text.disabled,
      fontSize: '0.875rem',
      wordBreak: 'break-word',
    },
    tableCell: {
      padding: '1rem',
    },
  }));

  const classes = useStylesDialog(props);

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const { data: log, isFetching, refetch: refetchLog } = useQuery(
    'fetch-log',
    () => req.get(`/usuario/historico-alteracao?relacao=contrato&relacaoId=${data}`, { token }),
    { enabled: show },
  );

  useEffect(() => {
    if (show) {
      refetchLog();
    }
  }, [show, refetchLog]);

  return (
    <Dialog
      open={showDialog}
      classes={{ paper: classes.paper }}
      onBackdropClick={() => {
        setShowDialog(false);
        onClose(false);
      }}
    >
      <DialogTitle>
        <Typography color="primary">Histórico de Alterações</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          {!isFetching && log && (
            <Table width="100%">
              <TableHead className={cls(classes.borderHighlight, classes.tableHead)}>
                <TableRow>
                  <TableCell width="15%" align="center" className={classes.tableCell}>
                    Tipo
                  </TableCell>
                  <TableCell width="40%" align="center" className={classes.tableCell}>
                    Valor
                  </TableCell>
                  <TableCell width="22%" align="center" className={classes.tableCell}>
                    Alteração
                  </TableCell>
                  <TableCell width="23%" align="center" className={classes.tableCell}>
                    Usuário
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {log
                  .filter(
                    item =>
                      item?.dados?.tipo &&
                      ![HistoricoAlteracaoAcao.RECALCULO_PROPOSTA, HistoricoAlteracaoAcao.CRIACAO_PROPOSTA].includes(
                        item.dados.tipo,
                      ),
                  )
                  .map(item => {
                    let valor = '';
                    let usuario = '';

                    if (item.dados.tipo === HistoricoAlteracaoAcao.PAGAMENTO_CONTRATO) {
                      valor = `${item.dados.diasUteisPagamento.valorNovo} ${
                        item.dados.diasUteisPagamento.valorNovo === 1 ? 'dia útil' : 'dias úteis'
                      }`;
                    } else if (item.dados.tipo === HistoricoAlteracaoAcao.PRECO_PROPOSTA) {
                      const inicio = DateTime.fromISO(item.dados.dataInicio);
                      const fim = DateTime.fromISO(item.dados.dataFim).minus({ days: 1 });

                      let periodo = '';

                      if (inicio.month === fim.month && inicio.year === fim.year) {
                        periodo = inicio.toFormat('LLL yyyy');
                      } else if (inicio.month === 1 && fim.month === 12 && inicio.year === fim.year) {
                        periodo = inicio.toFormat('yyyy');
                      } else {
                        periodo = `${inicio.toFormat('LLL yyyy')} - ${fim.toFormat('LLL yyyy')}`;
                      }
                      valor = `${periodo} • ${parseFloat(item.dados.precoMwh.valorNovo)
                        .toFixed(2)
                        .replace('.', ',')}/MWh`;
                    } else if (item.dados.tipo === HistoricoAlteracaoAcao.CANCELAMENTO_CONTRATO) {
                      valor = 'Cancelamento do contrato';
                    } else if (item.dados.tipo === HistoricoAlteracaoAcao.DATA_ASSINATURA_CONTRATO) {
                      const novaData = DateTime.fromISO(item.dados.dataAssinatura.valorNovo);
                      valor = novaData.toFormat('dd/MM/yyyy');
                    } else if (item.dados.tipo === HistoricoAlteracaoAcao.PERCENTUAL_ATENDIMENTO) {
                      valor = `${item.dados.fatiamentoAtendimento.valorNovo}%`;
                    } else if (item.dados.tipo === HistoricoAlteracaoAcao.DATA_BASE_PROPOSTA) {
                      const novaData = DateTime.fromISO(item.dados.dataBase.valorNovo);
                      valor = novaData.toFormat('dd/MM/yyyy');
                    } else if (item.dados.tipo === HistoricoAlteracaoAcao.CONTRATANTE_CONTRATO) {
                      valor = item.dados.contratante.nomeEmpresaValorNovo;
                    }

                    const nome = item?.usuario?.nome?.split(' ') || [];

                    if (item.usuario.tipo === HistoricoAlteracaoUsuarioTipo.BACKSTAGE) {
                      usuario = `${nome[0]} ${nome[1] ? nome[1].charAt(0).toUpperCase() : ''} (cx)`;
                    } else if (item.usuario.tipo === HistoricoAlteracaoUsuarioTipo.DIGITAL_COM) {
                      usuario = `${nome[0]} ${nome[1] ? nome[1].charAt(0).toUpperCase() : ''}`;
                    }

                    return (
                      <TableRow className={classes.tableRow} key={item.id}>
                        <TableCell width="15%" align="center" className={classes.tableCell}>
                          <Typography className={classes.grey}>
                            {
                              {
                                [HistoricoAlteracaoAcao.PAGAMENTO_CONTRATO]: 'Pagamento',
                                [HistoricoAlteracaoAcao.PRECO_PROPOSTA]: 'Preço',
                                [HistoricoAlteracaoAcao.CANCELAMENTO_CONTRATO]: 'Cancelamento',
                                [HistoricoAlteracaoAcao.DATA_ASSINATURA_CONTRATO]: 'Assinatura',
                                [HistoricoAlteracaoAcao.PERCENTUAL_ATENDIMENTO]: 'Percentual Atend.',
                                [HistoricoAlteracaoAcao.DATA_BASE_PROPOSTA]: 'Data Base',
                                [HistoricoAlteracaoAcao.CONTRATANTE_CONTRATO]: 'Contratante',
                              }[item?.dados?.tipo]
                            }
                          </Typography>
                        </TableCell>
                        <TableCell width="40%" align="center" className={classes.tableCell}>
                          <Typography className={classes.grey}>{valor}</Typography>
                        </TableCell>
                        <TableCell width="22%" align="center" className={classes.tableCell}>
                          <Typography className={classes.grey}>
                            {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm')}
                          </Typography>
                        </TableCell>
                        <TableCell width="23%" align="center" className={classes.tableCell}>
                          <Typography className={classes.grey}>{usuario}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
          {isFetching && (
            <Box align="center">
              <CircularProgress />
            </Box>
          )}
          {!isFetching && !log && (
            <Box align="center">
              <Typography variant="caption">Nenhum histórico</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowDialog(false);
            onClose(false);
          }}
          color="primary"
          autoFocus
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

HistoricoDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired,
};
function PagamentoDialog(props) {
  const { show, onClose, contrato, changeDueDays } = props;
  const { token } = useAuth();
  const [showDialog, setShowDialog] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '' });

  function handleCloseAlert() {
    setAlert({ show: false, message: '' });
  }

  const useStylesPagamento = makeStyles(() => ({
    paper: { maxWidth: '290px' },
  }));

  const classes = useStylesPagamento(props);

  const [inputs, handleChange, , , setInputs] = useForm({ diasUteisPagamento: '' });

  const [saveDate] = useMutation(
    postData => req.put(`/backstage/contrato/${contrato.id}/vencimento`, postData, { token }),
    {
      onSuccess: () => {
        changeDueDays(contrato.id, parseInt(inputs.diasUteisPagamento, 10));
        setShowDialog(false);
        onClose(false);
        setAlert({
          show: true,
          msg: 'Data de pagamento do contrato alterada',
        });
      },
      onError: error => {
        setShowDialog(false);
        onClose(false);
        setAlert({ show: true, msg: error.message });
      },
    },
  );

  useEffect(() => {
    setShowDialog(show);
    if (show) {
      setInputs({ diasUteisPagamento: contrato.diasUteisPagamento });
    }
  }, [show, setInputs, contrato.diasUteisPagamento]);

  return (
    <>
      <Dialog open={showDialog} classes={{ paper: classes.paper }}>
        <DialogTitle>
          <Box pr={6}>
            <Typography variant="body1">Alterar Data de Pagamento do Contrato</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form onChange={handleChange}>
            <Box>
              <Box p={1}>
                <TextField
                  type="text"
                  label="Dia"
                  variant="outlined"
                  name="diasUteisPagamento"
                  value={inputs.diasUteisPagamento}
                  fullWidth
                  required
                  autoFocus
                />
              </Box>
              <Box p={1}>
                <TextField select label="Contagem" value="1" variant="outlined" fullWidth>
                  <MenuItem value="1">Dias úteis</MenuItem>
                </TextField>
              </Box>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDialog(false);
              onClose(false);
            }}
            color="primary"
          >
            Voltar
          </Button>
          <Button
            onClick={() => {
              saveDate({ diasUteisPagamento: parseInt(inputs.diasUteisPagamento, 10) });
              onClose(false);
            }}
            disabled={inputs.diasUteisPagamento === contrato.diasUteisPagamento}
            color="primary"
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.show}
        autoHideDuration={5000}
        message={alert.msg}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </>
  );
}

PagamentoDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contrato: PropTypes.shape({
    id: PropTypes.string,
    diasUteisPagamento: PropTypes.number,
  }).isRequired,
  changeDueDays: PropTypes.func.isRequired,
};

function RescisaoDialog(props) {
  const { show, onClose } = props;

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  return (
    <Dialog open={showDialog}>
      <DialogTitle>
        <Typography variant="body1">Deseja rescindir o contrato?</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box p={1}>
            <TextField name="justificativa" value="" label="Justificativa" multiline rows={7} variant="outlined" />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowDialog(false);
            onClose(false);
          }}
          color="primary"
        >
          Voltar
        </Button>
        <Button
          onClick={() => {
            setShowDialog(false);
            onClose(false);
          }}
          color="primary"
          autoFocus
        >
          Rescindir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RescisaoDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

function AlterarPrazoAssinaturaDialog(props) {
  const { show, data, onClose, changeValidityDate } = props;

  const dataValidade = data?.contrato?.dataValidade;
  const [showDialog, setShowDialog] = useState(false);
  const [aumentarPrazoEm, setAumentarPrazoEm] = useState(0);
  const [novaDataAssinatura, setNovaDataAssinatura] = useState(dataValidade);
  const [alert, setAlert] = useState({ show: false, msg: '' });
  const { token } = useAuth();

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const formatISOString = (isoString, style = 'dd/MM/yyyy') => {
    if (isoString) {
      return DateTime.fromISO(isoString).toFormat(style);
    }
    return '';
  };

  const handleAumentarPrazoEm = event => {
    const value = event.target.value === '' ? 0 : event.target.value;
    if (value <= 60 && !isNil(Number(value))) {
      setAumentarPrazoEm(parseInt(value, 10));
    }
  };

  const handleCloseAlert = () => {
    setAlert({ show: false, message: '' });
  };

  const handleFecharModal = () => {
    setShowDialog(false);
    setAumentarPrazoEm(0);
    onClose(false);
  };

  const [saveDate] = useMutation(
    postData => req.put(`/backstage/contrato/${data.contrato.id}/dataValidade`, postData, { token }),
    {
      onSuccess: () => {
        changeValidityDate(data.contrato.id, novaDataAssinatura.toISOString());
        handleFecharModal();
        setAlert({
          show: true,
          msg: 'Data de assinatura alterada',
        });
      },
      onError: error => {
        setShowDialog(false);
        onClose(false);
        setAlert({ show: true, msg: error.message });
      },
    },
  );

  const handleClickSalvar = () => {
    if (dataValidade !== novaDataAssinatura.toISOString()) {
      saveDate({ dataValidade: novaDataAssinatura.toISOString() });
    }
    handleFecharModal();
  };

  useEffect(() => {
    if (dataValidade) {
      const dataValidadeAtual = new Date(dataValidade);
      const novaData = Calendar.dateAfterDiasUteis(Number(aumentarPrazoEm), DateTime.fromJSDate(dataValidadeAtual));

      setNovaDataAssinatura(novaData.toJSDate());
    }
  }, [dataValidade, aumentarPrazoEm]);

  return (
    <>
      <Dialog open={showDialog}>
        <DialogTitle>
          <Typography variant="body1">Alterar Data de Assinatura do Contrato</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box pb={1} pt={1}>
              {renderReadonlyText('DATA DE ASSINATURA ATUAL', formatISOString(dataValidade))}
            </Box>
            <Box pb={2} pt={2}>
              <TextField
                name="aumentarPrazoEm"
                value={aumentarPrazoEm}
                onChange={handleAumentarPrazoEm}
                label="Aumentar o prazo em (dias úteis)"
                InputLabelProps={{ style: { color: 'rgba(0, 0, 0, 0.6)' } }}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box pb={2} pt={1}>
              {renderReadonlyText('NOVA DATA DE ASSINATURA', formatISOString(novaDataAssinatura?.toISOString()))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleFecharModal();
            }}
            color="primary"
          >
            Voltar
          </Button>
          <Button
            disabled={!aumentarPrazoEm}
            onClick={() => {
              handleClickSalvar();
            }}
            color="primary"
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.show}
        autoHideDuration={5000}
        message={alert.msg}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </>
  );
}

AlterarPrazoAssinaturaDialog.propTypes = {
  data: PropTypes.shape({
    contrato: {
      id: PropTypes.string,
      dataValidade: PropTypes.string,
    },
  }).isRequired,
  changeValidityDate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

function AlterarDataBaseDialog(props) {
  const { show, data, onClose, setContratos } = props;

  const { token } = useAuth();
  const useStyleAlterarDataBase = makeStyles(() => ({
    paper: { width: '400px', maxHeight: '307px' },
  }));

  const classes = useStyleAlterarDataBase(props);

  const { id, dataBase } = data;

  const [alert, setAlert] = useState({ show: false, msg: '' });

  const [novaDataBase, setNovaDataBase] = useState(null);

  const [dataBaseAtual, setDataBaseAtual] = useState(null);

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseAlert = () => {
    setAlert({ show: false, message: '' });
  };

  const fechaModal = () => {
    setShowDialog(false);
    onClose(false);
  };

  const [saveDataBase] = useMutation(
    postData => req.put(`/backstage/proposta/${id}/updateDataBase`, postData, { token }),
    {
      onSuccess: () => {
        fechaModal();
        setNovaDataBase(null);
        setDataBaseAtual(novaDataBase);
        setContratos(id, novaDataBase);
        setAlert({
          show: true,
          msg: 'Data Base alterada',
        });
      },
      onError: () => {
        fechaModal();
        setAlert({ show: true, msg: 'Não foi possível alterar a Data Base. Tente novamente mais tarde.' });
      },
    },
  );

  const handleClickSalvar = () => {
    if (dataBase !== novaDataBase) {
      saveDataBase({ dataBase: novaDataBase.startOf('month').toISODate() });
    }
    fechaModal();
  };

  function formatISOString(isoString, style = 'MM/yyyy') {
    return isoString ? DateTime.fromISO(isoString).toFormat(style) : '';
  }

  useEffect(() => {
    setDataBaseAtual(dataBase);
  }, [dataBase]);

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  return (
    <>
      <Dialog open={showDialog} classes={{ paper: classes.paper }}>
        <DialogTitle>
          <Typography variant="body1">Alterar Data Base da proposta</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body2">
            Escolha a data que deseja para ser a nova Data Base da proposta
          </DialogContentText>
          <Box>
            <Box pb={1} pt={1}>
              {renderReadonlyText('DATA BASE ATUAL', formatISOString(dataBaseAtual))}
            </Box>
            <Box pt={1} pb={1}>
              <KeyboardDatePicker
                label="Nova Data Base"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                views={['year', 'month']}
                format="MM/yyyy"
                value={novaDataBase}
                onChange={newValue => {
                  setNovaDataBase(newValue);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                initialFocusedDate={DateTime.local().startOf('month')}
                maxDate={DateTime.local().endOf('year')}
                maxDateMessage="A data não pode ser maior do que o mês atual"
                fullWidth
                autoOk
              />
            </Box>
            <DialogActions>
              <Button
                onClick={() => {
                  fechaModal();
                }}
                color="primary"
              >
                Voltar
              </Button>
              <Button disabled={!novaDataBase} onClick={() => handleClickSalvar()} color="primary">
                Salvar
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={alert.show}
        autoHideDuration={5000}
        message={alert.msg}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </>
  );
}

AlterarDataBaseDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    dataBase: PropTypes.string,
  }).isRequired,
  setContratos: PropTypes.func.isRequired,
};

export {
  HistoricoDialog,
  PagamentoDialog,
  RescisaoDialog,
  AlterarPrazoAssinaturaDialog,
  AlterarDataBaseDialog,
  CancelarContratoDialog,
};
